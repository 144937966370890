import { ComponentProps, MouseEvent, useState } from "react";
import { ReactNode } from "react";
import cn from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCurrentUserApi } from "../api/current-user-api";
import { ROUTES } from "../App";
import { consts } from "../public-consts";
import { Icon, _Icon } from "../ui/Icon";
import { Spinner } from "../ui/Spinner";
import { Type100 } from "../ui/Typography";

export const Page = ({
  children,
  title,
  className,
  actions,
}: { title?: string, actions?: ReactNode } & ComponentProps<"div">) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUserApi = useCurrentUserApi();
  const [isSigningOut, setisSigningOut] = useState(false);

  const handleSignOutClick = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setisSigningOut(true);
    await currentUserApi.signOut();
    navigate(ROUTES.HOME);
  };

  type _NavItem = {
    name: string;
    icon: _Icon;
    route: string;
    loading?: boolean;
    onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  };

  const navItems: _NavItem[] = [
    {
      name: "Dashboard",
      icon: "dashboard",
      route: ROUTES.HOME,
    },
    {
      name: "ARC Requests",
      icon: "circleCheck",
      route: ROUTES.ARC_REQUESTS,
    },
    {
      name: "Calendar",
      icon: "calendar",
      route: ROUTES.CALENDAR,
      // }, {
      //   name: "Communication",
      //   icon: "chat",
      //   route: '',
    }, {
      name: "Directory",
      icon: "building",
      route: ROUTES.DIRECTORY,
    },
    {
      name: "Documents",
      icon: "document",
      route: ROUTES.DOCUMENTS,
      // }, {
      //   name: "Projects",
      //   icon: "layers",
      //   route: '',
    },
    {
      name: "Settings",
      icon: "gear",
      route: ROUTES.SETTINGS,
    },
  ];

  const navButton = ({ route, name, onClick, icon, loading }: _NavItem) => {
    const isActive = name !== "Sign Out" && location.pathname === route;
    const _className = cn(
      "flex items-center py-2.5 pl-4 w-[180px] mb-1 mx-3 rounded-[3px] relative overflow-hidden hover:bg-gray text-grayText hover:text-base",
      {
        "bg-gray font-semibold text-base": isActive,
      }
    );

    return (
      <Link to={route} className={_className} key={name} onClick={onClick}>
        {isActive && (
          <div className="bg-primary w-1 h-full mr-3 absolute left-0" />
        )}
        {loading ? (
          <Spinner color="darkBlue" />
        ) : (
          <Icon
            className={isActive ? "fill-primary" : "fill-grayText"}
            size="sm"
            name={icon}
          />
        )}
        <span className="ml-2">{name}</span>
      </Link>
    );
  };

  return (
    <div className="h-full flex">
      <nav className="flex flex-col h-full bg-grayDark drop-shadow-md items-start justify-between">
        <div>
          <img
            className="place-self-center my-12 mx-auto"
            src={consts.publicLogoPath}
            alt={consts.companyName}
          />
          {navItems.map((navItem) => navButton(navItem))}
        </div>
        <div className="mb-3">
          {navButton({
            name: "Sign Out",
            icon: "logout",
            route: ROUTES.HOME,
            loading: isSigningOut,
            onClick: handleSignOutClick,
          })}
        </div>
      </nav>
      <div className="h-full w-full overflow-scroll p-10 flex flex-col">
        <div className="u-spread mb-8">
          {title && <Type100>{title}</Type100>}
          {actions}
        </div>
        <div className={`h-full ${className ? className : ""}`}>{children}</div>
      </div>
    </div>
  );
};
