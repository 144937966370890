import { useEffect, useState } from "react";
import cn from "classnames";
import { useDropzone } from "react-dropzone";
import { useFoldersApi } from "../api/folders-api";
import { Button } from "../ui/Button";
import { Icon } from "../ui/Icon";
import { Modal } from "../ui/Modal";
import { Spinner } from "../ui/Spinner";
import { uploadS3File } from "../utils/file-utils";

export const FileUploadModal = ({
  onClose,
  folderId,
  onUploadComplete,
}: {
  onClose: () => void;
  onUploadComplete: (id: number) => void;
  folderId: number;
}) => {
  const foldersApi = useFoldersApi();
  const [finishedFileNames, setFinishedFileNames] = useState<string[]>([]);
  const { acceptedFiles, getRootProps, getInputProps, isDragAccept } = useDropzone();

  const uploadFiles = async () => {
    for (const file of acceptedFiles) {
      const signedId = await uploadS3File(file);
      await foldersApi.update(folderId, { addDocuments: [signedId] });
      setFinishedFileNames(finishedFileNames => finishedFileNames.concat([file.name]));
    }
    onUploadComplete(folderId);
  }

  useEffect(() => {
    if (acceptedFiles.length) {
      uploadFiles();
    }
  }, [acceptedFiles]);

  const hasAcceptedFiles = Boolean(acceptedFiles.length);
  const allFilesUploaded = finishedFileNames.length === acceptedFiles.length;
  const _className = cn(
    "bg-[#cfe7fb] border-dashed border-[#789cbd] border-2 text-[#789cbd] flex flex-col items-center px-12 py-24 rounded-md",
    {
      "py-24": !hasAcceptedFiles,
      "py-8": hasAcceptedFiles,
    }
  );

  return (
    <Modal
      onClose={onClose}
      title={
        hasAcceptedFiles
          ? allFilesUploaded
            ? "Upload complete"
            : "Uploading files..."
          : "Add files to folder"
      }
    >
      {hasAcceptedFiles ? (
        <>
          {acceptedFiles.map((file) => {
            const isFinished = finishedFileNames.includes(file.name);
            return (
              <div
                className="bg-offWhite flex justify-between items-cente p-2 rounded-[3px] mt-2"
                key={file.name}
              >
                <div>{file.name}</div>
                <div className="flex items-center">
                  {isFinished ? (
                    <Icon name="check" size="sm" className="fill-green" />
                  ) : (
                    <Spinner color="darkBlue" />
                  )}
                  <div className="ml-2">
                    {isFinished ? "Added" : "Uploading"}
                  </div>
                </div>
              </div>
            );
          })}
          <Button
            skin="primary"
            className="mt-8 float-right"
            onClick={onClose}
            disabled={!allFilesUploaded}
          >
            Done
          </Button>
        </>
      ) : (
        <div {...getRootProps({ className: _className })}>
          <input {...getInputProps()} />
          <Icon
            className="fill-[#789cbd] mb-2"
            name={isDragAccept ? "files" : "upload"}
            size="lg"
          />
          <div>
            {isDragAccept
              ? "Drop to upload"
              : "Drag and drop or click to select files"}
          </div>
        </div>
      )}
    </Modal>
  );
};
