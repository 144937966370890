import { api } from "../api/api";

export const useDocumentsApi = () => {
  return {
    get: async (id: number) => {
      try {
        const response = await api.documents.get(id);
        return response.data.url;
      } catch (e: any) {
        return console.error(e.message);
      }
    },
  };
};
