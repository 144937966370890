import { ReactNode, useEffect, useState } from "react";
import cn from "classnames";
import { useField } from "formik";
import { useDropzone } from "react-dropzone";
import {useError} from "../hooks/useError";
import { Icon } from "../ui/Icon";
import { Spinner } from "../ui/Spinner";
import { uploadS3File } from "../utils/file-utils";
import { FormFieldLabel } from "./FormFieldLabel";

type FileUploadProps = {
  name: string;
  error?: ReactNode;
  label?: string;
  multiple?: boolean;
  clearable?: boolean;
  value: string[];
  onChange: (files: string[]) => void;
}

export const FileUpload = ({ name, error, label, multiple, clearable, onChange, value }: FileUploadProps) => {
  const { acceptedFiles, getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    maxFiles: multiple ? 0 : 1,
    multiple: multiple,
  });

  const [progressTracker, setProgressTracker] = useState<Record<string, string | null>>({});

  const uploadFiles = async () => {
    const signedIds: string[] = [];
    for (const file of acceptedFiles) {
      const signedId = await uploadS3File(file);
      signedIds.push(signedId)
      setProgressTracker(progressTracker => ({ ...progressTracker, [file.name]: signedId }))
    }
    onChange(value.concat(signedIds));
  }

  useEffect(() => {
    if (acceptedFiles.length) {
      const newProgressTracker = { ...progressTracker };
      acceptedFiles.map(f => newProgressTracker[f.name] = null)
      setProgressTracker(newProgressTracker)
      uploadFiles()
    }
  }, [acceptedFiles]);
  
  const handleRemoveFileClick = (fileName: string) => () => {
    onChange(value.filter(v => v !== progressTracker[fileName]))
    const newProgressTracker = { ...progressTracker }
    delete newProgressTracker[fileName]
    setProgressTracker(newProgressTracker);
  }

  const hasFiles = Boolean(value.length);

  const _className = cn("border-dashed border px-2 py-2 rounded-[3px]", {
    "text-[#789cbd] bg-[#cfe7fb] border-[#789cbd]": !isDragReject,
    "border-danger text-danger": isDragReject,
  });

  const _iconClassName = cn({
    "fill-[#789cbd]": !isDragReject,
    "fill-danger": isDragReject
  })

  const renderFiles = () => {
    return (
      <>
        {Object.keys(progressTracker).map((name) => {
          const isFinished = Boolean(progressTracker[name]);
          return (
            <div
              className="bg-offWhite flex justify-between items-cente p-2 rounded-[3px] mb-2"
              key={name}
            >
              <div>{name}</div>
              <div className="flex items-center">
                {!isFinished && (
                  <>
                    <Spinner color="darkBlue" />
                    <div className="ml-2">Uploading...</div>
                  </>
                )}
                {isFinished && clearable && (
                  <Icon className="cursor-pointer fill-danger" onClick={handleRemoveFileClick(name)} name="remove" size="sm" />
                )}
                {isFinished && !clearable && (
                  <>
                    <Icon name="check" size="sm" className="fill-green" />
                    <div className="ml-2">Added</div>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </>
    )
  }

  return (
    <FormFieldLabel name={name} label={label} error={error}>
      {renderFiles()}
      {(multiple || !hasFiles) && (
        <div {...getRootProps({ className: _className })}>
          <input {...getInputProps()} />
          <div className="flex w-full items-center">
            <Icon
              className={_iconClassName}
              name={isDragAccept ? "files" : "upload"}
              size="sm"
            />
            <div className="ml-2">
              {(!isDragAccept && !isDragReject && !hasFiles) && "Drag and drop or click to select files"}
              {(!isDragAccept && !isDragReject && hasFiles) && "Add additional files"}
              {isDragAccept && "Drop to upload"}
              {isDragReject && !multiple && "Only one file permitted"}
            </div>
          </div>
        </div>
      )}
    </FormFieldLabel>
  )
}

export const FormikFileUpload = ({ name, ...props }: Omit<FileUploadProps, 'onChange' | 'value'>) => {
  const [field, meta, helpers] = useField({ name });
  const error = useError(meta);

  const onChange = (value: string[]) => {
    helpers.setValue(value);
  }

  return <FileUpload error={error} {...field} {...props} onChange={onChange} />;
};
