import { useEffect, useState } from "react"
import { Formik, Form, FormikHelpers, FormikValues } from "formik"
import { useRecoilValue } from "recoil";
import * as Yup from 'yup';
import { buildingsState, useBuildingsApi } from "../api/buildings-api";
import { usePeopleApi } from "../api/people-api"
import { PersonType, PersonTypeDictionary } from "../consts"
import { Button } from "../ui/Button"
import { FormField } from "../ui/FormField"
import { FormikSelect } from "../ui/Select"
import { FormikTextField } from "../ui/TextField"
import { keys } from "../utils/utils"

export const OccupantForm = ({ onComplete, onAddBuildingClick, autoFocus }: { onComplete: VoidFunc, onAddBuildingClick: VoidFunc, autoFocus?: boolean }) => {
  const buildings = useRecoilValue(buildingsState)
  const buildingsApi = useBuildingsApi();
  const peopleApi = usePeopleApi();
  
  const [isAddingAnother, setIsAddingAnother] = useState(false);

  useEffect(() => {
    buildingsApi.getList(1)
  }, [])

  const handleSaveAndAddPersonClick = (submitForm: () => Promise<void>) => () => {
    setIsAddingAnother(true);
    submitForm();
  }
    
  const handlePersonSubmit = async (values: FormikValues, { resetForm }: FormikHelpers<any>) => {
    await peopleApi.create(values);
    if (isAddingAnother) {
      setIsAddingAnother(false)
      resetForm();
    } else {
      onComplete();
    }
  }

  if (!buildings) {
    // IF (03/06/22): TODO loading state
    return null;
  }

  return (
    <>
      <Formik
        initialValues={{
          unitAttributes: {
            identifier: '',
            buildingId: buildings[0].id.toString(),
          },
          type: PersonType.owner,
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        }}
        onSubmit={handlePersonSubmit}
        validationSchema={
          Yup.object({
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            unitAttributes: Yup.object({
              buildingId: Yup.string().required("Required"),
              identifier: Yup.string().required("Required"),
            })
          })
        }
      >
        {({ submitForm }) => {
          return (
            <Form>
              <FormField>
                <FormikSelect
                  label="Building"
                  name="unitAttributes.buildingId"
                  options={buildings.map(b => ({ label: b.name, value: b.id.toString() }))}
                  onAddClick={onAddBuildingClick}
                  addLabel="Add Building"
                />
              </FormField>
              <FormField>
                <FormikTextField
                  label="Unit Number"
                  name="unitAttributes.identifier"
                  required
                  autoFocus={autoFocus}
                />
              </FormField>
              <FormField>
                <FormikSelect
                  label="Occupant Type"
                  name="type"
                  options={keys(PersonTypeDictionary).map(key => ({ label: PersonTypeDictionary[key], value: key }))}
                />
              </FormField>
              <FormField>
                <FormikTextField
                  label="First Name"
                  name="firstName"
                  required
                />
              </FormField>
              <FormField>
                <FormikTextField
                  label="Last Name"
                  name="lastName"
                  required
                />
              </FormField>
              <FormField>
                <FormikTextField
                  label="Phone Number"
                  name="phone"
                />
              </FormField>
              <FormField>
                <FormikTextField
                  label="Email"
                  name="email"
                />
              </FormField>
              <div className="flex justify-end">
                <Button className="mr-2" type="button" onClick={handleSaveAndAddPersonClick(submitForm)}>Save &amp; Add Another</Button>
                <Button skin="primary" type="submit">Finish</Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
