import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import smartlookClient from 'smartlook-client'
import { currentUserState, useCurrentUserApi } from "./api/current-user-api";
import { Environment } from "./consts";
import { ArcRequests } from "./pages/ArcRequests";
import { Calendar } from "./pages/Calendar";
import { Dashboard } from "./pages/Dashboard";
import { Directory } from "./pages/Directory";
import { Documents } from "./pages/Documents";
import { Public } from "./pages/Public";
import { Settings } from "./pages/Settings";

export const ROUTES = {
  HOME: "/",
  SETTINGS: "/settings",
  ARC_REQUESTS: "/arc-requests",
  CALENDAR: "/calendar",
  DIRECTORY: "/directory",
  DOCUMENTS: "/documents",
} as const;

export const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useRecoilValue(currentUserState);
  const currentUserApi = useCurrentUserApi();
  const authToken = localStorage.getItem("authToken");

  const [currentUserIsLoading, setCurrentUserIsLoading] = useState(
    Boolean(authToken)
  );

  const missingCurrentUser = authToken && !currentUser && !currentUserIsLoading;
  const needsRedirect =
    location.pathname !== ROUTES.HOME && (!authToken || missingCurrentUser);

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    if (needsRedirect) {
      navigate(ROUTES.HOME, { state: { initialPath: location.pathname } });
    } else if (currentUserIsLoading) {
      await currentUserApi.get();
      setCurrentUserIsLoading(false);
    }
  };

  if (needsRedirect) {
    return null;
  }

  if (currentUserIsLoading) {
    return <div>Loading...</div>;
  }

  // @ts-ignore
  if (process.env.NODE_ENV === Environment.development && currentUser && !window.smartlook) {
    smartlookClient.init('da6d56032365a5d9fce445547f5f01eaf9f6c087');
    smartlookClient.identify(currentUser.id, {
      email: currentUser.email,
    })
  }

  return (
    <>
      <Routes>
        <Route
          path={ROUTES.HOME}
          element={authToken && currentUser ? <Dashboard /> : <Public />}
        />
        <Route path={ROUTES.ARC_REQUESTS} element={<ArcRequests />} />
        <Route path={ROUTES.CALENDAR} element={<Calendar />} />
        <Route path={ROUTES.DIRECTORY} element={<Directory />} />
        <Route path={ROUTES.DOCUMENTS} element={<Documents />} />
        <Route path={ROUTES.SETTINGS} element={<Settings />} />
      </Routes>
      <div id="confirmPortal" />
      <div id="modalPortal" />
    </>
  );
};
