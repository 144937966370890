import {ReactNode} from 'react';
import classnames from 'classnames';
import {Icon} from './Icon';

export const Checkbox = ({ value, children, onChange }: { value: boolean, children: ReactNode, onChange: VoidFunc }) => {
  const _className = classnames({
    'border-2': !value,
    'bg-green': value,
  })
  return (
    <div className="flex items-center cursor-pointer" onClick={onChange}>
      <div className={`h-4 w-4 rounded-sm mr-2 ${_className}`}>
        {value && <Icon size="xs" name="checkBold" className="fill-white" />}
      </div>
      <div>{children}</div>
    </div>
  );
}
