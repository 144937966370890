import axios from "axios";

const ENDPOINTS = {
  USER_FETCH: "current-user",
  USER_SIGN_IN: "sign-in",
  USER_SIGN_UP: "sign-up",

  ARC_REQUEST: "arc_requests",
  ARC_REQUEST_FORM: "arc_request_forms",
  BUILDINGS: "buildings",
  PEOPLE: "people",
  DOCUMENTS: "documents",
  FOLDERS: "folders",
  UNITS: "units",

  HOAHQ_SLACK: "https://hooks.slack.com/services/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",
};

const SLACK_BOTS = {
  WAITING_LIST: { username: "Waiting List", icon_emoji: ":pencil:" },
  CONTACT_US: { username: "Contact Us", icon_emoji: ":incoming_envelope:" },
};

const base = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// IF (02/16/22): This header needs to go in an interceptor because the value
// of the localStorage authToken can change so it it can't be hardcoded into
// the base
base.interceptors.request.use((config) => {
  // @ts-ignore
  config.headers.Authorization = `Bearer ${localStorage.getItem("authToken")}`;
  return config;
});

const slackRequest = async (
  bot: keyof typeof SLACK_BOTS,
  data: { text: string }
) => {
  return axios.post(
    ENDPOINTS.HOAHQ_SLACK,
    {
      channel: "#hoahq",
      ...SLACK_BOTS[bot],
      ...data,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};

export const api = {
  arcRequests: {
    create: async (data: any) => base.post(ENDPOINTS.ARC_REQUEST, data),
    update: async (id: number, data: ArcRequestUpdateReq): Promise<{ data: ArcRequest }> => base.put(ENDPOINTS.ARC_REQUEST + `/${id}`, { arcRequest: data }),
    getList: async (hoaId: number) => base.get(ENDPOINTS.ARC_REQUEST + `?hoaId=${hoaId}`),
  },
  arcRequestForm: {
    fetch: async (hoaId: number) => base.get(ENDPOINTS.ARC_REQUEST_FORM + `?hoaId=${hoaId}`),
  },
  buildings: {
    create: async (data: any) => base.post(ENDPOINTS.BUILDINGS, data),
    getList: async (hoaId: number) => base.get(ENDPOINTS.BUILDINGS + `?hoaId=${hoaId}`),
  },
  currentUser: {
    get: async () => base.get(ENDPOINTS.USER_FETCH),
    signIn: async (data: { email: string; password: string }) => base.post(ENDPOINTS.USER_SIGN_IN, data),
    signUp: async (data: { email: string; password: string }) => base.post(ENDPOINTS.USER_SIGN_UP, data),
  },
  documents: {
    get: async (id: number) => base.get(ENDPOINTS.DOCUMENTS + `/${id}`),
    create: async (data: any) => base.post(ENDPOINTS.DOCUMENTS, data),
    delete: async (id: string) => base.delete(ENDPOINTS.DOCUMENTS + `/${id}`),
  },
  folders: {
    fetch: async (id: number) => base.get(ENDPOINTS.FOLDERS + `?hoaId=${id}`),
    update: async (id: number, data: FolderUpdateReq): Promise<{ data: Folder }> => base.put(ENDPOINTS.FOLDERS + `/${id}`, data),
    create: async (data: FolderCreateReq) => base.post(ENDPOINTS.FOLDERS, data),
    delete: async (id: number) => base.delete(ENDPOINTS.FOLDERS + `/${id}`),
  },
  people: {
    create: async (data: any) => base.post(ENDPOINTS.PEOPLE, data),
    getList: async (hoaId: number) => base.get(ENDPOINTS.PEOPLE + `?hoaId=${hoaId}`),
  },
  slack: {
    waitingList: (data: { text: string }) => slackRequest("WAITING_LIST", data),
    contactUs: (data: { text: string }) => slackRequest("CONTACT_US", data),
  },
  units: {
    create: async (data: any) => base.post(ENDPOINTS.UNITS, data),
    getList: async (hoaId: number) => base.get(ENDPOINTS.UNITS + `?hoaId=${hoaId}`),
  },
};
