import { ComponentProps, ReactNode } from "react";
import cn from "classnames";
import { useField } from "formik";
import {useError} from "../hooks/useError";
import {FormFieldLabel} from "./FormFieldLabel";

export const TextArea = ({
  label,
  error,
  className,
  required,
  ...props
}: { label?: string; error?: ReactNode, required?: boolean, name: string } & ComponentProps<"textarea">) => {
  const style = cn("p-2 border border-black rounded-[3px] w-full", className, {
    "border-base": !error,
    "border-danger": error,
  });

  return (
    <FormFieldLabel name={props.name} label={label} error={error} required={required}>
      <textarea {...props} className={style} />
    </FormFieldLabel>
  );
};

export const FormikTextArea = ({
  label,
  name,
  ...props
}: { label?: string; name: string } & ComponentProps<"textarea">) => {
  const [field, meta] = useField(name);
  const error = useError(meta);
  return <TextArea label={label} error={error} {...field} {...props} />;
};
