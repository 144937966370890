import { useState } from "react"
import { Formik, Form, FormikHelpers, FormikValues } from "formik"
import * as Yup from 'yup';
import { useBuildingsApi } from "../api/buildings-api"
import { Button } from "../ui/Button"
import { FormField } from "../ui/FormField"
import { FormikTextField } from "../ui/TextField"

export const BuildingForm = ({ onComplete, isWizard, buildingList, autoFocus }: { onComplete: () => void, isWizard?: boolean, buildingList?: string, autoFocus?: boolean; }) => {
  const buildingsApi = useBuildingsApi();
  
  const [isAddingAnother, setIsAddingAnother] = useState(false);
  
  const handleSaveAndAddClick = (submitForm: () => Promise<void>) => () => {
    setIsAddingAnother(true);
    submitForm();
  }

  const handleBuildingSubmit = async (values: FormikValues, { resetForm }: FormikHelpers<any>) => {
    // IF (03/06/22): TODO Check if the form is clean or the initial values or something instead
    if (values.name) {
      await buildingsApi.create(values);
    }

    if (isAddingAnother) {
      setIsAddingAnother(false)
      resetForm();
    } else {
      onComplete();
    }
  }

  const initialValues = {
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '', 
  }

  const validationSchema = Yup.object({
    name: isWizard && buildingList ? Yup.string() : Yup.string().required("Required"),
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleBuildingSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm }) => {
        return (
          <Form>
            <FormField>
              <FormikTextField
                label="Building Name"
                name="name"
                required
                autoFocus={autoFocus}
              />
            </FormField>
            <FormField>
              <FormikTextField
                label="Address"
                name="address"
              />
            </FormField>
            <FormField>
              <FormikTextField
                label="City"
                name="city"
              />
            </FormField>
            <FormField className="flex space-x-4">
              <FormikTextField
                label="State"
                name="state"
              />
              <FormikTextField
                label="Zip"
                name="zip"
              />
            </FormField>
            {buildingList && (
              <FormField>{buildingList}</FormField>
            )}
            <div className="flex justify-end">
              <Button className="mr-2" type="button" onClick={handleSaveAndAddClick(submitForm)}>Save &amp; Add Another</Button>
              <Button skin="primary" type="submit">{isWizard ? "Next" : "Submit"}</Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
