import { useState } from "react";
import { Form, Formik, FormikValues } from "formik";
import * as Yup from "yup";
import { useFoldersApi } from "../api/folders-api";
import { Button } from "../ui/Button";
import { Modal } from "../ui/Modal";
import { FormikTextField } from "../ui/TextField";

export const CreateFolderModal = ({
  onClose,
  onCreate,
  parentId,
}: {
  onClose: () => void;
  onCreate: (id: number) => void;
  parentId: number;
}) => {
  const foldersApi = useFoldersApi();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async ({ name }: FormikValues) => {
    setLoading(true);
    await foldersApi.create({ name, parentId, hoaId: 1 });
    setLoading(false);
    onCreate(parentId);
    onClose();
  };

  return (
    <Modal onClose={onClose} title="Add Folder">
      <Formik
        initialValues={{ folderName: "" }}
        validationSchema={Yup.object({
          name: Yup.string().required("Required"),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormikTextField name="name" placeholder="Folder Name" autoFocus />
          <div className="flex justify-between items-center mt-10">
            {loading ? (
              <div />
            ) : (
              <Button type="button" onClick={onClose} skin="dismiss">
                Cancel
              </Button>
            )}
            <Button loading={loading} skin="primary" type="submit">
              Add
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};
