import { atom, useRecoilState } from "recoil";
import { api } from "./api";

export const buildingsState = atom<Building[] | null>({
  key: "buildingsState",
  default: null,
});

export const useBuildingsApi = () => {
  const [buildings, setBuilding] = useRecoilState(buildingsState);

  return {
    create: async (data: any) => {
      try {
        const resp = await api.buildings.create(data);
        setBuilding(buildings ? buildings.concat([resp.data]) : [resp.data]);
      } catch (e: any) {
        return console.error(e.message);
      }
    },
    getList: async (hoaId: number) => {
      try {
        const response = await api.buildings.getList(hoaId);
        setBuilding(response.data);
      } catch (e: any) {
        return console.error(e.message);
      }
    },
  };
};
