import cn from "classnames";

export const Spinner = ({
  color = "white",
  className,
}: {
  color?: string;
  className?: string;
}) => {
  const _className = cn(
    "w-4 h-4 border-2 rounded-full animate-spin",
    className,
    {
      "border-t-white border-white/50": color === "white",
      "border-t-darkBlue border-darkBlue/50": color === "darkBlue",
    }
  );

  return <div className={_className} />;
};
