import { ChangeEvent, useState } from "react";
import { useLocation } from "react-router-dom";
import { api } from "../api/api";
import { ContactUsModal } from "../components/ContactUsModal";
import {UiAnimation} from "../components/UiAnimation";
import {
  FORM_TYPES,
  UserFormModal,
  _FormType,
} from "../components/UserFormModal";
import { ENV } from "../env";
import { ReactComponent as LogoDark } from "../img/logo-dark.svg";
import { PageContainer } from "../layouts/PageContainer";
import { consts } from "../public-consts";
import { Button } from "../ui/Button";
import { Icon } from "../ui/Icon";
import { LinkText } from "../ui/LinkText";
import { Modal } from "../ui/Modal";
import { TextField } from "../ui/TextField";
import { _u } from "../utils/utils";

const JoinOurWaitingList = ({ skin }: { skin?: Skin }) => {
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmationVisible, setConfirmationVisible] =
    useState<boolean>(false);

  const submit = async () => {
    setIsLoading(true);
    api.slack.waitingList({ text: email });
    await _u.sleep(500);
    setIsLoading(false);
    setConfirmationVisible(true);
    setEmail("");
  };

  const handleKeyDown = (e: any) => {
    if (e.code === "Enter") {
      submit();
    }
  };

  const handleClick = () => {
    if (email === "") {
      return;
    }
    submit();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleClose = () => setConfirmationVisible(false);

  return (
    <>
      <div className="flex items-center w-full max-w-md">
        <TextField
          placeholder="Email"
          name="email"
          onKeyDown={handleKeyDown}
          value={email}
          onChange={handleChange}
        />
        <Button onClick={handleClick} skin={skin} loading={isLoading} className="ml-2">
          Join Our Waiting List
        </Button>
      </div>
      {confirmationVisible && (
        <Modal onClose={handleClose}>
          <div className="space-y-8">
            <img
              className="pt-6 m-auto"
              src={consts.publicLogoPath}
              alt={consts.companyName}
            />
            <div className="text-2xl font-bold text-center">
            You're on the list!
            </div>
            <div className="">
            Thanks for your interest in hoaHQ, we'll reach out soon to get you
            on board. Becoming an early user has its perks...
            </div>
            <ul className="list-disc ml-5">
              <li>Exclusive access to new features</li>
              <li>Free while we're in closed beta</li>
              <li>24/7 customer support</li>
              <li>Provide valuable feedback</li>
              <li>
              Help us build a product <strong>you</strong> love using
              </li>
            </ul>
            <div className="flex justify-end">
              <Button onClick={handleClose} skin="primary" className="m-auto block">
                Got it!
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export const Public = () => {
  const location = useLocation();
  // IF (02/16/22): Not able to get the type here so typecasting for now
  const state = location.state as null | { initialPath: string };
  const initialPath = state ? state.initialPath : undefined;
  const [formType, setFormType] = useState<_FormType | null>(
    initialPath ? FORM_TYPES.SIGN_IN : null
  );
  const [contactUsVisible, setContactUsVisible] = useState<boolean>(false);

  const handleToggleUserForm = (newFormType: _FormType | null) => () => {
    setFormType(newFormType);
  };

  const handleFeaturesClick = () => {
    document?.getElementById("features")?.scrollIntoView();
  };

  return (
    <>
      <PageContainer className="bg-white drop-shadow-md">
        <nav className="flex justify-between items-center py-2">
          <img src={consts.publicLogoPath} alt={consts.companyName} />
          <div className="flex space-x-5 items-center">
            <Button
              skin="dismiss"
              onClick={handleFeaturesClick}
              className="msm:hidden"
            >
              Features
            </Button>
            <Button skin="dismiss" onClick={() => contactUsVisible}>
              Contact
            </Button>
            <Button
              onClick={handleToggleUserForm(FORM_TYPES.SIGN_IN)}
              skin={ENV.IN_BETA ? "primary" : "dismiss"}
            >
              Sign In
            </Button>
            {!ENV.IN_BETA && (
              <Button
                skin="primary"
                onClick={handleToggleUserForm(FORM_TYPES.SIGN_UP)}
              >
                Sign Up
              </Button>
            )}
          </div>
        </nav>
      </PageContainer>
      <PageContainer spaced>
        <div className="my-16 flex flex-col items-center">
          <div className="text-center text-5xl font-bold max-w-3xl">
            {consts.publicTitle}
          </div>
          <div className="text-center text-lg max-w-[460px] mt-3 mb-10">
            {consts.publicTagline}
          </div>
          <JoinOurWaitingList skin="primary" />
        </div>
      </PageContainer>
      <PageContainer
        className="relative"
        outsideContent={
          <div className="content-blank absolute bottom-0 bg-darkBlue w-full h-1/2" />
        }>
        {/*
          <div className="m-auto relative z-10">
            <AnimatedUi width="100%" height={undefined} /> 
          </div>
        */}
        <UiAnimation />
      </PageContainer>
      <PageContainer id="features" spaced className="bg-darkBlue text-white">
        <div className="text-5xl text-center font-bold mb-2">
          All The Essentials
        </div>
        <div className="text-2xl text-center font-light mb-16">
          One place to track, maintain, and manage your HOA
        </div>
        <div className="grid gap-4 grid-cols-3 mlg:grid-cols-2 msm:grid-cols-1">
          {consts.features.map((feature) => (
            <div
              key={feature.name}
              className="bg-darkBlueLight p-8 rounded-md transform origin-center hover:scale-105 hover:bg-darkBlueHighlight transition duration-150 ease-in-out"
            >
              <Icon
                name={feature.icon}
                style={{ fill: feature.color }}
                size="md"
              />
              <div className="text-2xl text-offWhite my-4">{feature.name}</div>
              <div className="text-grayText">{feature.description}</div>
            </div>
          ))}
        </div>
      </PageContainer>
      <PageContainer spaced className="bg-primary">
        <div className="flex justify-between items-start">
          <div className="flex justify-between max-w-md w-full">
            <div>
              <div className="w-40">
                <LogoDark width="100%" height="100%" />
              </div>
              <div className="text-sm mt-2">
                &copy; {consts.companyName} {new Date().getFullYear()}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="text-lg font-bold">Company</div>
              <LinkText
                variant="dark"
                className="text-sm"
                onClick={() => setContactUsVisible(true)}
              >
                Contact Us
              </LinkText>
              <LinkText variant="dark" className="text-sm">
                Privacy Policy
              </LinkText>
              <LinkText variant="dark" className="text-sm">
                Terms of Service
              </LinkText>
            </div>
            <div className="flex flex-col">
              <div className="text-lg font-bold">Product</div>
              <LinkText
                variant="dark"
                className="text-sm"
                onClick={() => setContactUsVisible(true)}
              >
                Feedback
              </LinkText>
              <LinkText variant="dark" className="text-sm">
                Roadmap
              </LinkText>
            </div>
          </div>
          <div className="mlg:hidden">
            <div className="text-xl font-semibold mb-2">
              Get updates and early access to our platform!
            </div>
            <JoinOurWaitingList skin="secondary" />
          </div>
        </div>
      </PageContainer>
      {contactUsVisible && (
        <ContactUsModal onClose={() => setContactUsVisible(false)} />
      )}
      {formType && (
        <UserFormModal
          initialFormType={formType}
          onClose={handleToggleUserForm(null)}
        />
      )}
    </>
  );
};
