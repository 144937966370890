import { atom, useRecoilState } from "recoil";
import { api } from "./api";

export const unitsState = atom<Unit[] | null>({
  key: "unitsState",
  default: null,
});

export const useUnitsApi = () => {
  const [units, setUnit] = useRecoilState(unitsState);

  return {
    create: async (data: any) => {
      try {
        const resp = await api.units.create(data);
        setUnit(units ? units.concat([resp.data]) : [resp.data]);
      } catch (e: any) {
        return console.error(e.message);
      }
    },
    getList: async (hoaId: number) => {
      try {
        const response = await api.units.getList(hoaId);
        setUnit(response.data);
      } catch (e: any) {
        return console.error(e.message);
      }
    },
  };
};
