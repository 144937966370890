import { ReactNode } from "react"
import cn from "classnames";
import { Type20 } from "./Typography";

type TableProps = {
  headers: ReactNode[];
  rows: ReactNode[][];
}

export const Table = ({ headers, rows }: TableProps) => {
  const colCount = headers.length;

  // IF (03/06/22): This is necessary because of Tailwinds JIT mechanism
  // We can't just create the classes dynamically because they won't be 
  // generated and added to master stylesheet that way.
  const gridCols = cn({
    'grid-cols-5': colCount === 5,
    'grid-cols-6': colCount === 6,
  })

  return (
    <div>
      <div className={`grid ${gridCols} px-2`}>
        {headers.map((header, index) => <Type20 className="py-2" key={index}>{header}</Type20>)}
      </div>
      <div className="divide-y divide-grayText border-grayText border-y">
        {rows.map((row, index) => (
          <div key={index} className={`grid grid-cols-${colCount} py-4 px-2 hover:bg-grayDark`}>
            {row.map((rowElem, _index) => (
              <div key={_index}>{rowElem}</div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}