import { atom, useRecoilState } from "recoil";
import { api } from "../api/api";

export const currentUserState = atom<CurrentUser | null>({
  key: "currentUserState",
  default: null,
});

export const useCurrentUserApi = () => {
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

  return {
    // create: async (data: FolderCreateReq) => {
    //   try {
    //     const resp = await api.currentUser.create(data);
    //     setFolders(currentUser ? currentUser.concat([resp.data]) : [resp.data]);
    //   }
    //   catch (e: any) {
    //     return console.error(e.message);
    //   }
    // },
    // IF (02/16/22): We should also send a request to sign out the user
    // on the server
    get: async () => {
      try {
        const response = await api.currentUser.get();
        localStorage.setItem("authToken", response.data.authToken);
        setCurrentUser(response.data);
      } catch (e: any) {
        return console.error(e.message);
      }
    },
    signIn: async (data: { email: string; password: string }) => {
      try {
        const response = await api.currentUser.signIn(data);
        localStorage.setItem("authToken", response.data.authToken);
        setCurrentUser(response.data);
      } catch (e: any) {
        return console.error(e.message);
      }
    },
    signUp: async (data: { email: string; password: string }) => {
      try {
        const response = await api.currentUser.signUp(data);
        localStorage.setItem("authToken", response.data.authToken);
        setCurrentUser(response.data);
      } catch (e: any) {
        return console.error(e.message);
      }
    },
    signOut: async () => {
      try {
        localStorage.removeItem("authToken");
        setCurrentUser(null);
      } catch (e: any) {
        return console.error(e.message);
      }
    },
  };
};
