export const Environment: Record<Environment, Environment> = {
  development: 'development',
  production: 'production',
}

export const ArcRequestStatus: Record<ArcRequestStatus, ArcRequestStatus> = {
  incomplete: 'incomplete',
  ready_for_review: 'ready_for_review',
  approved: 'approved',
  in_progress: 'in_progress',
  finished: 'finished',
}

export const ArcRequestStatusDictionary: Record<ArcRequestStatus, string> = {
  incomplete: 'Incomplete',
  ready_for_review: 'Ready For Review',
  approved: 'Approved',
  in_progress: 'In Progress',
  finished: 'Finished',
}

export const PersonType: Record<PersonType, PersonType> = {
  owner: 'owner',
  tenant: 'tenant',
}

export const PersonTypeDictionary: Record<PersonType, string> = {
  owner: 'Owner',
  tenant: 'Tenant',
}
