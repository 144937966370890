import { ComponentProps } from "react";
import cn from "classnames";

export const PageContainer = ({
  children,
  className,
  spaced,
  outsideContent,
  ...props
}: {
  spaced?: boolean;
  outsideContent?: React.ReactNode;
} & ComponentProps<"div">) => {
  const _className = cn(className, { "py-10 mmd:py-6": spaced });

  return (
    <div className={_className} {...props}>
      <div className={`max-w-screen-xl m-auto px-0 mxl:px-6`}>{children}</div>
      {outsideContent}
    </div>
  );
};
