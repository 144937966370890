import { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { buildingsState, useBuildingsApi } from "../api/buildings-api"
import { Modal, ModalPage } from "../ui/Modal"
import {BuildingForm} from "./BuildingForm"
import { OccupantForm } from "./OccupantForm"

export const AddOccupantModal = ({ onClose }: { onClose: () => void}) => {
  const buildingsApi = useBuildingsApi();
  const buildings = useRecoilValue(buildingsState)

  const [currentModalPage, setCurrentModalPage] = useState<number>(0);

  useEffect(() => {
    !buildings && buildingsApi.getList(1)
  })

  if (!buildings) {
    return null;
  }

  const changeCurrentModalPage = (page: number) => () => setCurrentModalPage(page)
   
  return (
    <Modal 
      title="Add an owner or tenant" 
      onClose={onClose}
      modalPages={[
        <ModalPage key={1} title="Add Building" onBack={changeCurrentModalPage(0)} backText="Back to unit form">
          <BuildingForm onComplete={changeCurrentModalPage(0)} />
        </ModalPage>
      ]}
      currentModalPage={currentModalPage}
    >
      <OccupantForm autoFocus onComplete={onClose} onAddBuildingClick={changeCurrentModalPage(1)} />
    </Modal>
  )
}
