import { atom, useRecoilState } from "recoil";
import { api } from "./api";

export const peopleState = atom<Person[] | null>({
  key: "peopleState",
  default: null,
});

export const usePeopleApi = () => {
  const [people, setPeople] = useRecoilState(peopleState);

  return {
    create: async (data: any) => {
      try {
        const resp = await api.people.create(data);
        setPeople(people ? people.concat([resp.data]) : [resp.data]);
      } catch (e: any) {
        return console.error(e.message);
      }
    },
    getList: async (hoaId: number) => {
      try {
        const response = await api.people.getList(hoaId);
        setPeople(response.data);
      } catch (e: any) {
        return console.error(e.message);
      }
    },
  };
};
