import { useState } from "react";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { buildingsState, useBuildingsApi } from "../api/buildings-api";
import { Modal } from "../ui/Modal"
import { _u } from "../utils/utils";
import { BuildingForm } from "./BuildingForm";
import { OccupantForm } from "./OccupantForm";

export const DirectoryWizardModal = ({ onClose }: { onClose: () => void }) => {
  const buildings = useRecoilValue(buildingsState)
  const buildingsApi = useBuildingsApi();

  const [isLoading, setisLoading] = useState(buildings === null);
  const [wizardStep, setWizardStep] = useState<1 | 2 | 3>(1);

  useEffect(() => {
    !buildings && buildingsApi.getList(1);
  }, [])

  if (buildings === null) {
    return null;
  }

  const subtitle = wizardStep === 1 
    ? "Don't worry, setting up your directory is quick and easy. First, add a building..."
    : "And then add a unit and its owner or tenant...";

  return (
    <Modal 
      onClose={onClose} 
      title="Setup your directory" 
      subtitle={subtitle}
    >
      {wizardStep === 1 && (
        <BuildingForm 
          buildingList={buildings.length === 0 ? undefined : `${_u.joinWords(buildings.map(b => b.name))} ${buildings.length === 1 ? 'has' : 'have'} been added.`}
          onComplete={() => setWizardStep(2)} 
          isWizard 
        />
      )}
      {wizardStep === 2 && (
        <OccupantForm onComplete={onClose} onAddBuildingClick={() => setWizardStep(1)}/>
      )}
    </Modal>
  )
}
