import { useEffect, useState, MouseEvent } from "react";
import { useRecoilValue } from "recoil";
import { arcRequestsState, useArcRequestsApi } from "../api/arc-requests-api";
import { ArcRequestFormModal } from "../components/ArcRequestFormModal";
import {ArcRequestModal} from "../components/ArcRequestModal";
import { EmptyState } from "../components/EmptyState";
import { ArcRequestStatusDictionary, ArcRequestStatus } from "../consts";
import { Page } from "../layouts/Page";
import { Button } from "../ui/Button";
import { Card } from "../ui/Card";
import { Label, Type10, Type25 } from "../ui/Typography";
import { numDate } from "../utils/date-utils";
import { addS, keys } from "../utils/utils";

export const ArcRequests = () => {
  const arcRequests = useRecoilValue(arcRequestsState);
  const arcRequestsApi = useArcRequestsApi();
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [visibleArcId, setVisibleArcId] = useState<number | null>(null);

  useEffect(() => {
    arcRequestsApi.getList(1);
  }, [])

  const handleToggleForm = () => {
    setFormModalVisible((formModalVisible) => !formModalVisible);
  };

  const handleUpdateStatus = (id: number, status: ArcRequestStatus) => (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    arcRequestsApi.update(id, { status });
  }

  const renderEmptyState = () => {
    return (
      <EmptyState
        illustration="arcRequests"
        title="No requests yet"
        subtitle="Track ARC progress and collect approvals"
        actions={
          <Button size="lg" skin="primary" onClick={handleToggleForm}>
            Add A New Request
          </Button>
        }
      />
    );
  };

  const renderArcRequestSummary = (arcRequest: ArcRequest) => {
    const attachmentCount = arcRequest.arcRequestFormAttachmentValues.length
    const approvalsCount = arcRequest.arcRequestApprovals.length
    const approversCount = arcRequest.arcRequestApprovers.length
    const remainingApprovals = approversCount - approvalsCount;

    const skin = {
      [ArcRequestStatus.incomplete]: "danger",
      [ArcRequestStatus.ready_for_review]: "processing",
      [ArcRequestStatus.approved]: "success",
      [ArcRequestStatus.in_progress]: "success",
      [ArcRequestStatus.finished]: "success",
    } as Record<ArcRequestStatus, StatusSkin>

    const requiredStrings = arcRequest.arcRequestForm.arcRequestFormStrings.filter(s => s.isRequired)
    const missingRequiredStrings = requiredStrings.filter(s => !arcRequest.arcRequestFormStringValues.find(v => v.arcRequestFormString.id === s.id));
    const requiredAttachments = arcRequest.arcRequestForm.arcRequestFormAttachments.filter(a => a.isRequired)
    const missingRequiredAttachments = requiredAttachments.filter(a => !arcRequest.arcRequestFormAttachmentValues.find(v => v.arcRequestFormAttachment.id === a.id));
    const isComplete = missingRequiredStrings.length === 0 && missingRequiredAttachments.length === 0;

    return (
      <Card skin={skin[arcRequest.status]} className="mt-3 cursor-pointer" key={arcRequest.id} onClick={() => setVisibleArcId(arcRequest.id)}>
        <div className="u-spread">
          <Label>{arcRequest.type}</Label>
          <Label>{numDate(arcRequest.submittedOn)}</Label>
        </div>
        <div>{arcRequest.unit.building.name} - {arcRequest.unit.identifier}</div>
        <Type10>Attachments: {attachmentCount}</Type10>
        {arcRequest.status === ArcRequestStatus.ready_for_review && (
          <>
            <Type10>Approvals: {approvalsCount}/{approversCount}</Type10>
            <Button 
              size="sm" 
              className="mt-2"
              disabled={!!remainingApprovals}
              disabledTooltip={`Still need ${remainingApprovals} approval${addS(remainingApprovals)}`}
              onClick={handleUpdateStatus(arcRequest.id, ArcRequestStatus.approved)}
            >
              Move To Approved
            </Button>
          </>
        )}
        {arcRequest.status === ArcRequestStatus.approved && (
          <Button size="sm" className="mt-2" onClick={handleUpdateStatus(arcRequest.id, ArcRequestStatus.in_progress)}>
            Move To In-Progress
          </Button>
        )}
        {arcRequest.status === ArcRequestStatus.incomplete && (
          <Button 
            size="sm" 
            className="mt-2" 
            disabled={!isComplete}
            disabledTooltip="Missing required info"
            onClick={handleUpdateStatus(arcRequest.id, ArcRequestStatus.ready_for_review)}
          >
            Move To "Ready For Review"
          </Button>
        )}
        {arcRequest.status === ArcRequestStatus.in_progress && (
          <Button size="sm" className="mt-2" onClick={handleUpdateStatus(arcRequest.id, ArcRequestStatus.finished)}>
            Move To "Finished"
          </Button>
        )}
      </Card>
    )
  }

  return (
    <Page title="ARC Requests" actions={
      <div className="flex">
        <Button className="mr-2" onClick={handleToggleForm}>View Past ARCs</Button>
        <Button onClick={handleToggleForm}>Add ARC Request</Button>
      </div>
    }>
      {(!arcRequests || arcRequests.length === 0) ? (
        renderEmptyState()
      ) : (
        <>
          <div className="grid grid-cols-4 gap-4">
            {keys(ArcRequestStatusDictionary).map((status) => {
              return (
                <div key={status}>
                  <Type25>{ArcRequestStatusDictionary[status]}</Type25>
                  {arcRequests.filter(ar => ar.status === status).map(ar => {
                    return renderArcRequestSummary(ar)
                  })}
                </div>
              )
            })}
          </div>
        </>
      )}
      {formModalVisible && <ArcRequestFormModal onClose={handleToggleForm} />}
      {visibleArcId && <ArcRequestModal arcRequestId={visibleArcId} onClose={() => setVisibleArcId(null)} />}
    </Page>
  );
};
