import { CSSProperties } from "react";
import { ReactComponent as Building } from "../icons/building.svg";
import { ReactComponent as Calendar } from "../icons/calendar.svg";
import { ReactComponent as Chat } from "../icons/chat.svg";
import { ReactComponent as CheckBold } from "../icons/check-bold.svg";
import { ReactComponent as Check } from "../icons/check.svg";
import { ReactComponent as CheckboxBlank } from "../icons/checkbox-blank.svg";
import { ReactComponent as CheckboxChecked } from "../icons/checkbox-checked.svg";
import { ReactComponent as ChevronDoubleLeft } from "../icons/chevron-double-left.svg";
import { ReactComponent as ChevronDown } from "../icons/chevron-down.svg";
import { ReactComponent as CircleCheck } from "../icons/circle-check.svg";
import { ReactComponent as Close } from "../icons/close.svg";
import { ReactComponent as Currency } from "../icons/currency.svg";
import { ReactComponent as Dashboard } from "../icons/dashboard.svg";
import { ReactComponent as Document } from "../icons/document.svg";
import { ReactComponent as File } from "../icons/file.svg";
import { ReactComponent as Files } from "../icons/files.svg";
import { ReactComponent as FolderOpen } from "../icons/folder-open.svg";
import { ReactComponent as Folder } from "../icons/folder.svg";
import { ReactComponent as Gear } from "../icons/gear.svg";
import { ReactComponent as Help } from "../icons/help.svg";
import { ReactComponent as Layers } from "../icons/layers.svg";
import { ReactComponent as Logout } from "../icons/logout.svg";
import { ReactComponent as MenuDown } from "../icons/menu-down.svg";
import { ReactComponent as MenuRight } from "../icons/menu-right.svg";
import { ReactComponent as Remove } from "../icons/remove.svg";
import { ReactComponent as Robot } from "../icons/robot.svg";
import { ReactComponent as Search } from "../icons/search.svg";
import { ReactComponent as Upload } from "../icons/upload.svg";

// IF (02/04/22): Most of these are pulled from https://materialdesignicons.com/

const iconMap = {
  building: Building,
  calendar: Calendar,
  chat: Chat,
  check: Check,
  checkBold: CheckBold,
  checkboxChecked: CheckboxChecked,
  checkboxBlank: CheckboxBlank,
  chevronDoubleLeft: ChevronDoubleLeft,
  chevronDown: ChevronDown,
  circleCheck: CircleCheck,
  close: Close,
  currency: Currency,
  dashboard: Dashboard,
  document: Document,
  file: File,
  files: Files,
  folder: Folder,
  folderOpen: FolderOpen,
  gear: Gear,
  help: Help,
  layers: Layers,
  logout: Logout,
  menuDown: MenuDown,
  menuRight: MenuRight,
  remove: Remove,
  robot: Robot,
  search: Search,
  upload: Upload,
};

export type _Icon = keyof typeof iconMap;

export const Icon = ({
  name,
  size = "md",
  style,
  className,
  width,
  height,
  onClick,
}: {
  name: _Icon;
  size?: "xs" | "sm" | "md" | "lg";
  style?: CSSProperties;
  className?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
}) => {
  const Component = iconMap[name];

  const measurement = {
    xs: 15,
    sm: 20,
    md: 40,
    lg: 60,
  }[size];

  return (
    <Component
      className={className}
      style={style}
      width={width || measurement}
      height={height || measurement}
      onClick={onClick}
    />
  );
};
