import { useEffect, useState } from "react";
import { Form, Formik, FormikValues } from "formik";
import { useRecoilValue } from "recoil";
import * as Yup from "yup";
import { arcRequestFormsState, useArcRequestFormsApi } from "../api/arc-request-forms-api";
import { useArcRequestsApi } from "../api/arc-requests-api";
import {unitsState, useUnitsApi} from "../api/units-api";
import { ArcRequestStatus } from "../consts";
import { Button } from "../ui/Button";
import {FormikDatePicker} from "../ui/DatePicker";
import { FormikFileUpload } from "../ui/FileUpload";
import { FormField } from "../ui/FormField";
import { Modal, ModalPage } from "../ui/Modal";
import { FormikSelect } from "../ui/Select";
import { FormikTextArea } from "../ui/TextArea";
import { FormikTextField } from "../ui/TextField";
import { Type25 } from "../ui/Typography";
import { _u } from "../utils/utils";
import {BuildingForm} from "./BuildingForm";
import { confirmAction } from "./ConfirmModal";
import {OccupantForm} from "./OccupantForm";

export const ArcRequestFormModal = ({ onClose }: { onClose: () => void }) => {
  const arcRequestsApi = useArcRequestsApi();
  const arcRequestFormsApi = useArcRequestFormsApi();
  const arcRequestForms = useRecoilValue(arcRequestFormsState);
  const unitsApi = useUnitsApi()
  const units = useRecoilValue(unitsState);

  const [isLoading, setIsLoading] = useState(false);
  const [activeForm, setActiveForm] = useState<ArcRequestForm | null>(null);
  const [currentModalPage, setCurrentModalPage] = useState<number>(0);
  
  useEffect(() => {
    arcRequestFormsApi.fetch(1);
    unitsApi.getList(1);
  }, [])

  if (!arcRequestForms || !units) {
    return null;
  }

  if (arcRequestForms && !activeForm) {
    setActiveForm(arcRequestForms[0])
    return null;
  }

  if (!activeForm) {
    return null;
  }

  const handleFormTypeChange = (id: string) => {
    setActiveForm(arcRequestForms.find(a => a.id === parseInt(id)) || null);
  }

  const confirmSubmit = async (values: FormikValues) => {
    const missingStrings = activeForm
      .arcRequestFormStrings
      .filter(arfs => arfs.isRequired && !values[`string${arfs.id}`])
      .map(arfs => `"${arfs.label}"`)

    const missingAttachments = activeForm
      .arcRequestFormAttachments
      .filter(arfa => arfa.isRequired && !values[`attachment${arfa.id}`]?.length)
      .map(arfa => `"${arfa.label}"`)

    const missingCount = missingStrings.length + missingAttachments.length;

    if (missingCount === 0) {
      handleSubmit(values, ArcRequestStatus.ready_for_review);
      return;
    }

    const missingMessage = missingCount === 1 ? `is one required field` : `are ${missingCount} required fields`;
    const confirmationText = `There ${missingMessage} missing. Your ARC Request will be marked incomplete until ${_u.joinWords([...missingStrings, ...missingAttachments])} ${missingCount === 1 ? 'is' : 'are'} completed.`
    
    await confirmAction(confirmationText, {
      title: "Your ARC Request is Incomplete",
      confirmBtnText: "Submit as Incomplete",
      cancelBtnText: "Go Back",
      onConfirm: async () => await handleSubmit(values, ArcRequestStatus.incomplete),
    })
  }

  const handleSubmit = async (values: FormikValues, status: ArcRequestStatus) => {
    const data ={
      arc_request_form_id: values.arcRequestFormId,
      startingOn: values.startingOn,
      unitId: values.unitId,
      status,

      arcRequestFormStringValuesAttributes: Object.keys(values).filter(key => key.includes('string') && Boolean(values[key])).map(v => ({ 
        arcRequestFormStringId: v.replace('string', ''),
        value: values[v],
      })),
      arcRequestFormAttachmentValuesAttributes: Object.keys(values).filter(key => key.includes('attachment') && Boolean(values[key]?.length)).map(v => ({ 
        arcRequestFormAttachmentId: v.replace('attachment', ''),
        attachments: values[v],
      })),
    }

    await arcRequestsApi.create(data);
    await arcRequestsApi.getList(1);
    onClose();
  }

  const validationSchema: Record<string, any> = {
    startingOn: Yup.string().required("Required"),
    submittedOn: Yup.string().required("Required"),
    unitId: Yup.string().required("Required"),
  };
  const initialValues: Record<string, string | string[]> = {
    startingOn: "",
    submittedOn: "",
    unitId: "",
    arcRequestFormId: activeForm.id.toString(),
  };

  const stringFields = activeForm.arcRequestFormStrings.map(({ id, label, isMultiline, isRequired }) => {
    const key = `string${id}`;
    initialValues[key] = "";
    validationSchema[key] = isRequired ? Yup.string().required("Required") : Yup.string();

    return isMultiline ? (
      <FormField key={id}>
        <FormikTextArea
          rows={4}
          label={label}
          name={key}
          placeholder={label}
        />
      </FormField>
    ) : (
      <FormField key={id}>
        <FormikTextField
          label={label}
          name={key}
          type="text"
          placeholder={label}
        />
      </FormField>
    )
  });

  const renderAttachmentFields = activeForm.arcRequestFormAttachments.map(({ id, label, isMultiple }) => {
    const key = `attachment${id}`;
    initialValues[key] = [];
    
    return (
      <FormField key={id}>
        <FormikFileUpload clearable label={label} name={key} multiple={isMultiple} />
      </FormField>
    )
  })

  const changeCurrentModalPage = (page: number) => () => setCurrentModalPage(page)

  return (
    <>
      <Modal 
        onClose={onClose} 
        title="ARC Request"
        modalPages={[
          <ModalPage key={1} title="Add Unit" onBack={changeCurrentModalPage(0)} backText="Back to ARC form">
            <OccupantForm onComplete={changeCurrentModalPage(0)} onAddBuildingClick={changeCurrentModalPage(2)}/>
          </ModalPage>,
          <ModalPage key={2} title="Add Building" onBack={changeCurrentModalPage(1)} backText="Back to unit form">
            <BuildingForm onComplete={changeCurrentModalPage(1)} />
          </ModalPage>
        ]}
        currentModalPage={currentModalPage}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object(validationSchema)}
          onSubmit={confirmSubmit}
        >
          {() => {
            return (
              <Form>
                <FormField>
                  <FormikSelect 
                    name="arcRequestFormId"
                    label="Request Type"
                    placeholder="Select a type"
                    options={arcRequestForms.map(({ id, name }) => ({ value: id.toString(), label: name }))}
                    onChange={handleFormTypeChange}
                  />
                </FormField>
                <FormField>
                  <FormikSelect
                    label="Unit"
                    name="unitId"
                    placeholder="Unit"
                    options={units.map(u => ({ label: `${u.building.name} - ${u.identifier}`, value: u.id.toString() }))}
                    onAddClick={() => setCurrentModalPage(1)}
                    addLabel="Add Unit"
                  />
                </FormField>
                <FormField>
                  <FormikDatePicker
                    label="Submit Date"
                    name="submittedOn"
                    placeholder="Submit Date"
                  />
                </FormField>
                <FormField>
                  <FormikDatePicker
                    label="Start Date"
                    name="startingOn"
                    placeholder="Start Date"
                  />
                </FormField>
                {stringFields}
                {activeForm.arcRequestFormAttachments.length !== 0 && <Type25>Attachments</Type25>}
                {renderAttachmentFields}
                <div className="flex justify-end">
                  <Button skin="primary" className="" loading={isLoading} type="submit">
                  Submit
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </>
  );
};
