import { MouseEvent } from "react";
import { useState } from "react";
import { useEffect } from "react";
import cn from "classnames";
import { useRecoilValue } from "recoil";
import { useDocumentsApi } from "../api/documents-api";
import { foldersState, useFoldersApi } from "../api/folders-api";
import { confirmAction } from "../components/ConfirmModal";
import { CreateFolderModal } from "../components/CreateFolderModal";
import { EmptyState } from "../components/EmptyState";
import { FileUploadModal } from "../components/FileUploadModal";
import { Page } from "../layouts/Page";
import { Button } from "../ui/Button";
import { Icon } from "../ui/Icon";
import { _u } from "../utils/utils";

export const Documents = () => {
  const documentsApi = useDocumentsApi();
  const foldersApi = useFoldersApi();
  const folders = useRecoilValue(foldersState);

  const [uploadModalFolderId, setUploadModalFolderId] = useState<number | null>(
    null
  );
  const [createFolderModalId, setCreateFolderModalId] = useState<number | null>(
    null
  );
  const [openFolderIds, setOpenFolderIds] = useState<number[]>([]);

  useEffect(() => {
    foldersApi.fetch(1);
  }, []);

  if (!folders) {
    return null;
  }

  const handleToggleUploadModal =
    (id: number | null) => (e?: MouseEvent<HTMLButtonElement>) => {
      e?.stopPropagation();
      setUploadModalFolderId(id);
    };

  const handleToggleCreateFolderModal =
    (id: number | null) => (e?: MouseEvent<HTMLButtonElement>) => {
      e?.stopPropagation();
      setCreateFolderModalId(id);
    };

  const handleDocumentClick = (id: number) => async () => {
    const link = await documentsApi.get(id);
    window.open(link, "_blank");
  };

  const handleRemoveDocumentClick =
    (folderId: number, document: _Document) => async () => {
      await confirmAction(`Are you sure you want to remove "${document.filename}"?`,
        {
          onConfirm: () =>
            foldersApi.update(folderId, { removeDocuments: [document.id] }),
        }
      );
    };

  const removeFolderRequests = (id: number): Promise<any>[] => {
    const childFolderIds = folders
      .filter((f) => f.parentId === id)
      .map((f) => f.id);
    return [foldersApi.delete(id)].concat(
      ...childFolderIds.map((cfid) => removeFolderRequests(cfid))
    );
  };

  const handleOpenFolder = (id: number) => {
    if (!openFolderIds.includes(id)) {
      setOpenFolderIds((folders) => folders.concat([id]));
    }
  };

  const handleRemoveFolderClick =
    (folder: Folder) => async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      await confirmAction(
        `This will delete the "${folder.name}" folder along with all the folders and documents it contains.`,
        {
          onConfirm: () => Promise.all(removeFolderRequests(folder.id)),
        }
      );
    };

  const toggleFolderOpen = (toggleId: number) => () => {
    const folderIdsToClose = (closeId: number): number[] => {
      const childFolderIdsToClose = folders
        .filter((f) => f.parentId === closeId)
        .map((f) => f.id);
      return [closeId].concat(
        ...childFolderIdsToClose.map((id) => folderIdsToClose(id))
      );
    };

    setOpenFolderIds((openFolderIds) =>
      openFolderIds.includes(toggleId)
        ? openFolderIds.filter((id) => !folderIdsToClose(toggleId).includes(id))
        : openFolderIds.concat([toggleId])
    );
  };

  const toggleAllFoldersOpen = () => {
    setOpenFolderIds(openFolderIds.length ? [] : folders.map((f) => f.id));
  };

  const renderEmptyState = () => {
    const topLevelFolder = folders.find((f) => f.parentId === null);

    if (!topLevelFolder) {
      return null;
    }

    return (
      <EmptyState
        illustration="documents"
        title="No documents yet"
        subtitle="Organize and store all files associated with your HOA"
        actions={[
          <Button
            key="1"
            size="lg"
            skin="primary"
            onClick={handleToggleUploadModal(topLevelFolder.id)}
          >
            Add Documents
          </Button>,
          <Button
            key="2"
            size="lg"
            skin="primary"
            onClick={handleToggleCreateFolderModal(topLevelFolder.id)}
          >
            Add A Folder
          </Button>,
        ]}
      />
    );
  };

  const renderFolder = (folder: Folder, depth = -1) => {
    const { id, name, documents, parentId } = folder;
    const isTopLevelFolder = parentId === null;
    const folderIsOpen = openFolderIds.includes(id) || isTopLevelFolder;
    const childFolders = folders.filter((f) => f.parentId === id);
    const folderIsEmpty = documents.length === 0 && childFolders.length === 0;

    const rowStyle = (type?: "folder") =>
      cn("flex justify-between items-center py-1 rounded-sm px-1", {
        "hover:bg-grayDark": !type || !isTopLevelFolder,
        "cursor-pointer": type === "folder",
      });

    return (
      <div key={id}>
        <div
          className={rowStyle("folder")}
          onClick={isTopLevelFolder ? undefined : toggleFolderOpen(id)}
        >
          {isTopLevelFolder ? (
            <div>
              <Button size="sm" onClick={toggleAllFoldersOpen}>
                {openFolderIds.length ? "Collapse All" : "Expand All"}
              </Button>
            </div>
          ) : (
            <div
              className="flex items-center"
              style={{ paddingLeft: `${depth * 30}px` }}
            >
              <Icon name={folderIsOpen ? "menuDown" : "menuRight"} size="sm" />
              <Icon name={folderIsOpen ? "folderOpen" : "folder"} size="sm" />
              <div className="ml-2 flex items-center">
                <div>{name}</div>
                {folderIsOpen && folderIsEmpty && (
                  <div className="text-gray-500 text-xs ml-1">(empty)</div>
                )}
              </div>
            </div>
          )}
          <div className="flex items-center space-x-2">
            <Button size="sm" onClick={handleToggleCreateFolderModal(id)}>
              Add Folder
            </Button>
            <Button size="sm" onClick={handleToggleUploadModal(id)}>
              Upload
            </Button>
            {!isTopLevelFolder && (
              <Button size="sm" onClick={handleRemoveFolderClick(folder)}>
                Remove
              </Button>
            )}
          </div>
        </div>
        {folderIsOpen && (
          <>
            {Boolean(documents.length) &&
              _u.sortBy(documents, "filename").map((d) => (
                <div
                  key={d.id}
                  className={rowStyle()}
                  style={{ paddingLeft: `${(depth + 1) * 30}px` }}
                >
                  <div className="flex items-center ml-5">
                    <Icon name="file" size="sm" />
                    <div
                      className="ml-2 cursor-pointer hover:text-primary"
                      onClick={handleDocumentClick(d.id)}
                    >
                      {d.filename}
                    </div>
                  </div>
                  <Button size="sm" onClick={handleRemoveDocumentClick(id, d)}>
                    Remove
                  </Button>
                </div>
              ))}
            <div>
              {_u
                .sortBy(childFolders, "name")
                .map((f) => renderFolder(f, depth + 1))}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <Page title="Documents">
      {folders.length === 1 && folders[0].documents.length === 0
        ? renderEmptyState()
        : folders
          .filter((f) => f.parentId === null)
          .map((folder) => renderFolder(folder))}
      {uploadModalFolderId && (
        <FileUploadModal
          onClose={handleToggleUploadModal(null)}
          folderId={uploadModalFolderId}
          onUploadComplete={handleOpenFolder}
        />
      )}
      {createFolderModalId && (
        <CreateFolderModal
          onClose={handleToggleCreateFolderModal(null)}
          onCreate={handleOpenFolder}
          parentId={createFolderModalId}
        />
      )}
    </Page>
  );
};
