import { atom, useRecoilState } from "recoil";
import { api } from "./api";

export const arcRequestFormsState = atom<ArcRequestForm[] | null>({
  key: "arcRequestFormsState",
  default: null,
});

export const useArcRequestFormsApi = () => {
  const [_, setArcRequestForms] = useRecoilState(arcRequestFormsState);

  return {
    fetch: async (hoaId: number) => {
      try {
        const response = await api.arcRequestForm.fetch(hoaId);
        setArcRequestForms(response.data);
      } catch (e: any) {
        return console.error(e.message);
      }
    },
  };
};
