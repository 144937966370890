import { useState } from "react";
import { Form, Formik, FormikValues } from "formik";
import * as Yup from "yup";
import { useCurrentUserApi } from "../api/current-user-api";
import { ENV } from "../env";
import { consts } from "../public-consts";
import { Button } from "../ui/Button";
import { FormField } from "../ui/FormField";
import { LinkText } from "../ui/LinkText";
import { Modal } from "../ui/Modal";
import { FormikTextField } from "../ui/TextField";

export const FORM_TYPES = {
  SIGN_IN: "SIGN_IN",
  SIGN_UP: "SIGN_UP",
} as const;

export type _FormType = keyof typeof FORM_TYPES;

export const UserFormModal = ({
  onClose,
  initialFormType,
}: {
  onClose: () => void;
  initialFormType: _FormType;
}) => {
  const currentUserApi = useCurrentUserApi();
  const [isLoading, setIsLoading] = useState(false);
  const [formType, setFormType] = useState<_FormType>(initialFormType);

  const handleSignUpClick = () => {
    setFormType(FORM_TYPES.SIGN_UP);
  };

  const handleSignInClick = () => {
    setFormType(FORM_TYPES.SIGN_IN);
  };

  const handleSubmit = async ({ email, password }: FormikValues) => {
    setIsLoading(true);
    if (FORM_TYPES.SIGN_IN === formType) {
      await currentUserApi.signIn({ email, password });
    } else if (FORM_TYPES.SIGN_UP === formType) {
      await currentUserApi.signUp({ email, password });
    }
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal onClose={onClose} size="md">
      <div className="flex justify-center">
        <img
          className="py-6"
          src={consts.publicLogoPath}
          alt={consts.companyName}
        />
      </div>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          password: Yup.string()
            .min(8, "8 character minimum")
            .required("Required"),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormField>
            <FormikTextField
              label="Email"
              name="email"
              type="text"
              placeholder="Email"
              autoFocus
            />
          </FormField>
          <FormField>
            <FormikTextField
              label="Password"
              name="password"
              type="password"
              placeholder="Password"
            />
          </FormField>
          {!ENV.IN_BETA && (
            <FormField>
              {formType === FORM_TYPES.SIGN_UP && (
                <div className="text-center">
                  Already have an account?{" "}
                  <LinkText onClick={handleSignInClick}>Sign in</LinkText>
                </div>
              )}
              {formType === FORM_TYPES.SIGN_IN && (
                <div className="text-center">
                  Don't have an account yet?{" "}
                  <LinkText onClick={handleSignUpClick}>Sign up</LinkText>
                </div>
              )}
            </FormField>
          )}
          <div className="flex justify-end">
            <Button
              loading={isLoading}
              skin="primary"
              className="float-right"
              type="submit"
            >
              {formType === FORM_TYPES.SIGN_IN ? "Sign In" : "Sign Up"}
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};
