import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { currentUserState } from "../api/current-user-api";
import { Page } from "../layouts/Page";

export const Dashboard = () => {
  const navigate = useNavigate();
  const currentUser = useRecoilValue(currentUserState);
  const authToken = localStorage.getItem("authToken");

  const location = useLocation();
  // IF (02/16/22): Not able to get the type here so typecasting for now
  const state = location.state as null | { initialPath: string };
  const initialPath = state ? state.initialPath : undefined;

  // IF (02/16/22): If the user was trying to navigate to
  // an internal page but wasn't signed in, they get sent here
  // with the internalPath variable set in state
  useEffect(() => {
    if (currentUser && initialPath && authToken) {
      navigate(initialPath);
    }
  }, [currentUser, authToken]);

  return <Page title="Dashboard"></Page>;
};
