import { ComponentProps } from "react";
import cn from "classnames";

export const FormField = ({
  className,
  children,
  ...props
}: ComponentProps<"div">) => {
  const combinedClasses = cn("mb-4", className);
  return <div className={combinedClasses}>{children}</div>;
};
