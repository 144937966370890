import React, { MouseEvent, ReactNode, useEffect, useRef, useState } from "react";
import cn from "classnames";
import ReactDOM from "react-dom";
import {Button} from "./Button";
import {Icon} from "./Icon";
import { Type50 } from "./Typography";

const SIZE = {
  sm: { width: "200px" },
  md: { width: "400px" },
};

export const Modal = ({
  onClose,
  size,
  className,
  modalPages,
  currentModalPage = 0,
  ...modalPageProps
}: {
  onClose: () => void;
  size?: keyof typeof SIZE;
  modalPages?: ReactNode[];
  currentModalPage?: number;
} & ModalPageProps) => {
  const background = useRef(null);

  const [pageContainer, setPageContainer] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    //@ts-ignore
    document.addEventListener("keydown", handleKeyDown, false);
    document.body.classList.add("overflow-hidden");

    return () => {
      //@ts-ignore
      document.removeEventListener("keydown", handleKeyDown, false);
      document.body.classList.remove("overflow-hidden");
    };
  });

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === "Escape") {
      onClose();
    }
  };

  const handleBackgroundClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.target === background.current) {
      onClose();
    }
  };

  const _className = cn(
    className,
    "bg-white rounded-md drop-shadow-xl max-h-full overflow-x-clip overflow-y-scroll animate-dropIn",
    "transition-all duration-300 ease-in-out",
  );

  const modalPortal = document.querySelector("#modalPortal")

  if (!modalPortal) {
    return null;
  }

  // IF (03/22/22): These are weird calculations, for some reason chrome (at least) is always rendering the second
  // page first
  const modalPageShift = `${100 * currentModalPage}%`;
  const pageContainerHeight = pageContainer?.children[currentModalPage].clientHeight;

  return ReactDOM.createPortal(
    <div
      ref={background}
      className="fixed top-0 left-0 z-20 flex items-center justify-center w-full h-full p-8 bg-base/95 animate-fadeIn"
      onClick={handleBackgroundClick}
    >
      <div className={_className} style={SIZE[size || "md"]}>
        <div ref={setPageContainer} className="flex transition-all duration-300 ease-in-out items-start relative overflow-y-clip" style={{ right: modalPageShift, height: pageContainerHeight }}>
          <ModalPage {...modalPageProps} />
          {modalPages}
        </div>
      </div>
    </div>,
    modalPortal
  );
};

type ModalPageProps = {
  children: React.ReactNode;
  className?: string;
  title?: string;
  subtitle?: string;
  backText?: string;
  onBack?: VoidFunc;
  actions?: ReactNode;
}

export const ModalPage = ({ title, subtitle, children, onBack, backText, actions } : ModalPageProps) => {
  return (
    <div className="w-full flex-shrink-0 p-4">
      {backText && onBack && <Button skin="text" onClick={onBack}><Icon name="chevronDoubleLeft" size="sm"/>{backText}</Button>}
      {(title || actions) && (
        <div className={`u-spread ${subtitle ? "" : "mb-6"}`}>
          {title && <Type50>{title}</Type50>}
          {actions}
        </div>
      )}
      {subtitle && <div className="mt-2 mb-6 leading-snug text-gray-500">{subtitle}</div>}
      {children}
    </div>
  );
}
