import { ComponentProps } from "react";
import cn from 'classnames'

export const Card = ({ children, className, skin, ...props }: { skin?: StatusSkin } & ComponentProps<"div">) => {
  const _className = cn(
    className,
    'bg-white rounded-[3px] border-gray-400 border p-2',
    {
      'border-l-4': skin, 
      'border-l-danger': skin === "danger", 
      'border-l-yellow': skin === "processing", 
      'border-l-green': skin === "success", 
    }
  );

  return (
    <div className={_className} {...props}>
      {children}
    </div>
  );
};
