import {FieldMetaProps} from "formik";

export function useError(meta: FieldMetaProps<any>) {
  const isTouched = Boolean(meta.touched);
  const isError = Boolean(meta.error);

  return isError && isTouched ? (
    <div className="text-danger">{meta.error}</div>
  ) : undefined;
}
