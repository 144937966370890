import {useRecoilValue} from "recoil";
import {arcRequestsState, useArcRequestsApi} from "../api/arc-requests-api";
import {useDocumentsApi} from "../api/documents-api";
import {Button} from "../ui/Button";
import {Checkbox} from "../ui/Checkbox";
import {Icon} from "../ui/Icon";
import {LabeledData} from "../ui/LabeledData";
import { Modal } from "../ui/Modal";
import { Type30} from "../ui/Typography";
import {wordDate} from "../utils/date-utils";
import {fullName} from "../utils/utils";

export const ArcRequestModal = ({ arcRequestId, onClose }: { arcRequestId: number, onClose: () => void }) => {
  const arcRequests = useRecoilValue(arcRequestsState);
  const documentsApi = useDocumentsApi();
  const arcRequestsApi = useArcRequestsApi();

  const arcRequest = arcRequests?.find(ar => ar.id === arcRequestId);

  if (!arcRequest) {
    // TODO: Do something better here, load it?
    return null;
  }

  const handleAttachmentClick = (id: number) => async () => {
    const link = await documentsApi.get(id);
    window.open(link, "_blank");
  };

  const handleApproverClick = (arcRequestApproverId: number) => () => {
    const existingApproval = arcRequest.arcRequestApprovals.find(ara => ara.arcRequestApproverId === arcRequestApproverId) 
    if (existingApproval) {
      arcRequestsApi.update(arcRequest.id, { arcRequestApprovalsAttributes: [{ id: existingApproval.id, _destroy: true }] });
    } else {
      arcRequestsApi.update(arcRequest.id, { arcRequestApprovalsAttributes: [{ arcRequestApproverId }] });
    }
  }

  return (
    <Modal 
      title="Arc Request" 
      onClose={onClose} 
      actions={<Button size="sm">Edit</Button>}
    >
      <Type30 className="mb-2">Details</Type30>
      <LabeledData label="Unit" data={`${arcRequest.unit.building.name} - ${arcRequest.unit.identifier}`} />
      <LabeledData label="Submitted On" data={wordDate(arcRequest.submittedOn)} />
      <LabeledData label="Starting On" data={wordDate(arcRequest.startingOn)} />

      {arcRequest.arcRequestFormStringValues.map(arcRequestFormStringValue => {
        return (
          <LabeledData 
            key={arcRequestFormStringValue.id}
            label={arcRequestFormStringValue.arcRequestFormString.label}
            data={arcRequestFormStringValue.value}
          />
        )
      })}

      <Type30 className="mb-2">Attachments</Type30>
      {arcRequest.arcRequestFormAttachmentValues.map(arcRequestFormAttachmentValue => {
        return (
          <LabeledData
            key={arcRequestFormAttachmentValue.id}
            label={arcRequestFormAttachmentValue.arcRequestFormAttachment.label}
            data= {arcRequestFormAttachmentValue.attachments.map(attachment => (
              <div className="flex items-center" key={attachment.id}>
                <Icon name="file" size="sm" />
                <div
                  className="ml-2 cursor-pointer hover:text-primary"
                  onClick={handleAttachmentClick(attachment.id)}
                >
                  {attachment.filename}
                </div>
              </div>
            ))}
          />
        )
      })}
      <Type30 className="mb-2">Approvals</Type30>
      {arcRequest.arcRequestApprovers.map(({ id, person }) => {
        const isApproved = arcRequest.arcRequestApprovals.map(a => a.arcRequestApproverId).includes(id)
        return <Checkbox key={id} value={isApproved} onChange={handleApproverClick(id)}>{fullName(person)}</Checkbox>;
      })}

      <Type30 className="mb-2">Comments</Type30>
    </Modal>
  );
};
