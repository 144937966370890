import { ComponentProps } from "react";
import cn from "classnames";

export const LinkText = ({
  children,
  className,
  onClick,
  variant,
}: { variant?: "dark" | "gray" } & ComponentProps<"span">) => {
  const _className = cn(className, "cursor-pointer", {
    "text-primary": !variant,
    "text-darkBlueHighlight": variant === "dark",
    "text-gray-500": variant === "gray",
  });

  return (
    <span className={_className} onClick={onClick}>
      {children}
    </span>
  );
};
