import React, {ReactNode} from 'react';
import {Label} from './Typography';

type LabeledDataProps = {
  label: string; 
  data: ReactNode;
}

export const LabeledData = ({ label, data }: LabeledDataProps) => {
  return (
    <div className="mb-4">
      <Label>{label}</Label>
      <div>{data}</div>
    </div>
  );
}
