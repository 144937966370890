
import CryptoJS from "crypto-js";
import { api } from "../api/api";

export const uploadS3File = async (file: File) => {
  
  const checksum = await fileChecksum(file);
  const { data: presignedResp } = await api.documents.create({
    file: {
      filename: file.name,
      byteSize: file.size,
      checksum,
      contentType: file.type,
    },
  });

  const s3PutOptions = {
    method: "PUT",
    headers: {
      "Content-Type": presignedResp.directUpload.headers.contentType,
      "Content-MD5": presignedResp.directUpload.headers.contentMd5,
    },
    body: file,
  };
  let awsRes = await fetch(presignedResp.directUpload.url, s3PutOptions);
  if (awsRes.status !== 200) return awsRes;

  return presignedResp.blobSignedId
};

export const fileChecksum = async (file: File) => {
  const md5 = await md5FromFile(file);
  const checksum = md5.toString(CryptoJS.enc.Base64);
  return checksum;
};

// Note that for larger files, you may want to hash them incrementally.
// Taken from https://stackoverflow.com/questions/768268/
const md5FromFile = (file: File): Promise<CryptoJS.lib.WordArray> => {
  // FileReader is event driven, does not return promise
  // Wrap with promise api so we can call w/ async await
  // https://stackoverflow.com/questions/34495796
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (fileEvent) => {
      // @ts-ignore
      let binary = CryptoJS.lib.WordArray.create(fileEvent.target.result);
      const md5 = CryptoJS.MD5(binary);
      resolve(md5);
    };
    reader.onerror = () => {
      reject("oops, something went wrong with the file reader.");
    };
    // For some reason, readAsBinaryString(file) does not work correctly,
    // so we will handle it as a word array
    reader.readAsArrayBuffer(file);
  });
};
