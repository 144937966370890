import { ChangeEvent, useState } from 'react';
import { useEffect } from 'react';
import cn from 'classnames';
import { useField } from 'formik';
import ReactDOM from "react-dom";
import { usePopper } from 'react-popper';
import { ClickOutsideDetector } from '../components/ClickOutsideDetector';
import {useError} from '../hooks/useError';
import { _u } from '../utils/utils';
import { Icon } from './Icon'
import { TextField, TextFieldProps } from './TextField'

type SelectProps = Omit<TextFieldProps, 'onChange' | 'value' | 'name'> & {
  options: SelectOption[], 
  onChange: (value: string) => void, 
  value: string,
  name: string, 
}

export const Select = ({ options: _options, onChange, value, ...props }: SelectProps ) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [activeOptionIndex, setActiveOptionIndex] = useState(0);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    placement: 'bottom-start',
    modifiers: [{
      name: 'offset',
      options: {
        offset: [0, 4],
      },
    }],
  });

  useEffect(() => {
    if(value) {
      setInputValue(_options.find((o) => o.value === value)?.label || '')
    }
  }, [value])

  const options = _u.sortBy(
    _options.filter(({ label }) => _u.includesFilter(label, inputValue) || Boolean(value)),
    "label"
  );

  const handleOptionSelect = ({ value }: SelectOption) => {
    setOptionsVisible(false);
    onChange(value);
  }

  const handleOptionClick = (option: SelectOption) => () => handleOptionSelect(option)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOptionsVisible(true);
    setInputValue(e.target.value);
  }

  const handleMouseEnter = (index: number) => () => {
    setActiveOptionIndex(index)
  }

  const handleKeyDown = (e: any) => {
    if (["ArrowUp", "ArrowDown", "Escape", "Enter"].includes(e.code)) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (!optionsVisible) {
      return
    }

    switch (e.code) {
    case "ArrowUp":

      if (activeOptionIndex !== 0) {
        setActiveOptionIndex(activeOptionIndex => activeOptionIndex - 1)
      }
      break;
    case "ArrowDown":
      if (activeOptionIndex !== options.length - 1) {
        setActiveOptionIndex(activeOptionIndex => activeOptionIndex + 1)
      }
      break;
    case "Enter":
    case "Tab":
      handleOptionSelect(options[activeOptionIndex]);
      break;
    case "Escape":
      setOptionsVisible(false);
      break;
    default:
      onChange('');
      setActiveOptionIndex(0);
    }
  };

  const rootPortal = document.querySelector("#root")
  if (!rootPortal) {
    return null
  }

  return (
    <div>
      <div className="relative" ref={setReferenceElement} >
        <TextField {...props} className="pr-8 relative" value={inputValue} onClick={() => setOptionsVisible(true)} onChange={handleInputChange} autoComplete="off" onKeyDown={handleKeyDown}/> 
        <Icon name="chevronDown" size="sm" className="absolute top-9 right-2 cursor-pointer" onClick={() => setOptionsVisible(true)} />
      </div>
      {optionsVisible && ReactDOM.createPortal(
        <div ref={setPopperElement} className="border border-base bg-white rounded-[3px] z-50 w-full max-h-[180px] overflow-auto" style={{ ...styles.popper, width: referenceElement?.scrollWidth }} {...attributes.popper}>
          <ClickOutsideDetector onClickOutside={() => setOptionsVisible(false)}>
            {options.length === 0 && (
              <div className="p-4 text-gray-500 text-center text-sm">No matching options</div>
            )}
            {options.map((option, index) => {
              const hovered = index === activeOptionIndex;
              const selected = option.value === value;

              const _className = cn("p-2", {
                "bg-gray-400 cursor-pointer": hovered && !selected,
                "bg-primary text-white": selected,
              });

              return (
                <div
                  onMouseEnter={handleMouseEnter(index)}
                  key={option.value} 
                  className={_className} 
                  onClick={handleOptionClick(option)}
                >
                  {option.label}
                </div>
              )
            })}
          </ClickOutsideDetector>
        </div>,
        rootPortal
      )}
    </div>
  )
}

export const FormikSelect = ({ name, onChange, ...props }: Omit<SelectProps, 'value' | 'onChange'> & { onChange?: (val: string) => void }) => {
  const [field, meta] = useField(name);
  const error = useError(meta);
  const { onChange: formikOnChange, ...fieldProps } = field

  const handleChange = (val: string) => {
    formikOnChange(name)(val);
    onChange && onChange(val)
  }

  return <Select error={error} onChange={handleChange} {...fieldProps} {...props} />;
};
