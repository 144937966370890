import { ComponentProps } from "react";

export const Label = ({ children, className }: ComponentProps<"div">) => (
  <div className={`text-2xs font-bold uppercase ${className} text-gray-500`}>{children}</div>
);

export const Type10 = ({ children, className }: ComponentProps<"div">) => (
  <div className={`text-sm ${className}`}>{children}</div>
);

export const Type20 = ({ children, className }: ComponentProps<"div">) => (
  <div className={`text-md font-semibold ${className}`}>{children}</div>
);

export const Type25 = ({ children, className }: ComponentProps<"div">) => (
  <div className={`text-lg font-medium ${className}`}>{children}</div>
);

export const Type30 = ({ children, className }: ComponentProps<"div">) => (
  <div className={`text-xl font-medium ${className}`}>{children}</div>
);

export const Type50 = ({ children, className }: ComponentProps<"div">) => (
  <div className={`text-2xl font-medium ${className}`}>{children}</div>
);

export const Type100 = ({ children, className }: ComponentProps<"div">) => (
  <div className={`text-4xl font-medium ${className}`}>{children}</div>
);

export const Subtitle = ({ children, className }: ComponentProps<"div">) => (
  <div className={`text-gray-500 ${className}`}>{children}</div>
);
