import { ComponentProps, ReactNode } from "react";
import cn from "classnames";
import { useField } from "formik";
import {useError} from "../hooks/useError";
import { FormFieldLabel } from "./FormFieldLabel";
import { Icon, _Icon } from "./Icon";

export type TextFieldProps = { 
  label?: string; 
  error?: ReactNode; 
  size?: Size, 
  name: string, 
  icon?: _Icon, 
  required?: boolean;
  onAddClick?: () => void,
  addLabel?: string,
} & Omit<ComponentProps<"input">, "size">;

export const TextField = ({
  label,
  error,
  className,
  size,
  icon,
  required,
  onAddClick,
  addLabel,
  ...props
}: TextFieldProps) => {
  const sizeStyle = {
    "text-sm px-1": size === "sm",
    "p-2": !size || size === "md",
    "text-lg p-2": size === "lg",
  };

  const _className = cn(
    "border rounded-[3px] w-full",
    className,
    {
      "border-base": !error,
      "border-danger": error,
    },
    sizeStyle
  );

  return (
    <FormFieldLabel name={props.name} label={label} error={error} required={required} onAddClick={onAddClick} addLabel={addLabel}>
      <div className="relative">
        {icon && <Icon height={26} width={26} size="md" className="absolute top-2 left-1.5 fill-gray-500" name={icon} />}
        <input className={`${_className} ${icon ? 'pl-9' : ''}`} {...props} />
      </div>
    </FormFieldLabel>
  );
};

export const FormikTextField = ({ name, ...props }: TextFieldProps) => {
  const [field, meta] = useField(name);
  const error = useError(meta);
  return <TextField error={error} {...field} {...props} />;
};
