import { ReactNode } from "react";
import { ReactComponent as EmptyStateArcRequests } from "../img/empty-state-arc-requests.svg";
import { ReactComponent as EmptyStateDirectory } from "../img/empty-state-directory.svg";
import { ReactComponent as EmptyStateDocuments } from "../img/empty-state-documents.svg";
import { Subtitle, Type50 } from "../ui/Typography";

export const ILLUSTRATIONS = {
  arcRequests: EmptyStateArcRequests,
  directory: EmptyStateDirectory,
  documents: EmptyStateDocuments,
};

export const EmptyState = ({
  illustration,
  title,
  subtitle,
  actions,
}: {
  illustration: keyof typeof ILLUSTRATIONS;
  title: string;
  subtitle: string;
  actions: ReactNode;
}) => {
  const IllustrationComponent = ILLUSTRATIONS[illustration];

  return (
    <div className="flex flex-col items-center h-full justify-center w-full -mt-8">
      <Type50>{title}</Type50>
      <Subtitle>{subtitle}</Subtitle>
      <IllustrationComponent className="w-full max-w-xs my-10" />
      <div className="flex space-x-2 items-center">{actions}</div>
    </div>
  );
};
