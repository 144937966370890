export const consts = {
  companyName: "hoaHQ",
  features: [
    {
      name: "Project management",
      description:
        "Collect bids, gather consense, and track progress on all ongoing projects",
      icon: "layers",
      color: "#9061f9",
    },
    {
      name: "Maintenance calendar",
      description:
        "Retain an entire history of your building and keep an eye on what work is coming up",
      icon: "calendar",
      color: "#e64694",
    },
    {
      name: "Owner communication",
      description: "Send announcements, schedule reminders, and receive alerts",
      icon: "chat",
      color: "#ffa312",
    },
    {
      name: "Budgeting",
      description:
        "Create a comprehensive financial plan and track your progress throughout the year",
      icon: "currency",
      color: "#58c358",
    },
    {
      name: "Document storage",
      description:
        "Welcome packets, governing documents, meeting minutes and more all in one place",
      icon: "document",
      color: "#3e82f8",
    },
    {
      name: "Owner surveys",
      description:
        "Collect feedback from owners and tenants to help improve your processes",
      icon: "help",
      color: "#f05152",
    },
    {
      name: "ARC approvals",
      description: "Track ARCs as they get submitted and go through review",
      icon: "circleCheck",
      color: "#fd8b32",
    },
    {
      name: "Automations",
      description:
        "Auto-respond to messages and regularly generate important reports ",
      icon: "robot",
      color: "#fde232",
    },
    {
      name: "More coming soon!",
      description:
        "With your ideas and feedback we're improving hoaHQ everyday!",
      icon: "gear",
      color: "#32c9fd",
    },
  ],
  publicLogoPath: "/hoahq.svg",
  publicTitle: "Manage your HOA like a pro",
  publicTagline:
    "Use hoaHQ to stay organized, automate repetitive tasks, and respond quickly to important issues",
} as const;
