import {createElement, forwardRef} from 'react';
import {useField} from 'formik';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {useError} from '../hooks/useError';
import { TextField, TextFieldProps} from './TextField';

type DatePickerProps = Omit<TextFieldProps, 'onChange' | 'value'> & {
  onChange: (date: string) => void;
  value: string;
}

export const DatePicker = ({ onChange, value, ...props }: DatePickerProps ) => {
  const dateValue = value ? new Date(value) : null;
  const handleChange = (date: Date | null) => onChange(date ? date.toUTCString() : '');

  const DatePickerTextField = forwardRef(({ value, onClick }: { value: string; onClick: (event: React.MouseEvent<HTMLInputElement>) => void }, _ref: any) => (
    <TextField value={value} onChange={() => {}} {...props} onClick={onClick} autoComplete="off" />
  ));

  return (
    <ReactDatePicker 
      selected={dateValue} 
      onChange={handleChange} 
      customInput={createElement(DatePickerTextField)}
    />
  );
}

export const FormikDatePicker = ({ name, ...props }: Omit<DatePickerProps, 'value' | 'onChange'>) => {
  const [field, meta] = useField(name);
  const error = useError(meta);
  const { onChange: formikOnChange, ...fieldProps } = field

  const handleChange = (val: string) => {
    formikOnChange(name)(val);
  }

  return <DatePicker error={error} onChange={handleChange} {...fieldProps} {...props} />;
};
