export const _u = {
  sleep: (ms: number) => new Promise((r) => setTimeout(r, ms)),

  sortBy: <T>(arr: T[], key: keyof T) =>
    [...arr].sort((a, b) => (a[key] > b[key] ? 1 : -1)),

  includesFilter: (label: string, filter: string) =>
    label.replace(' ', '').toLocaleLowerCase().includes(filter.replace(' ', '').toLocaleLowerCase()),

  joinWords: (arr: string[]) => {
    switch(arr.length) {
    case 0: 
      return '';
    case 1:
      return arr[0];
    case 2:
      return `${arr[0]} and ${arr[1]}`;
    default:
      const lastWord = arr.pop();
      return `${arr.join(', ')}, and ${lastWord}`;
    }
  },
  
};

export function keys<T>(object: T) {
  return Object.keys(object) as (keyof T)[];
};

export const fullName = (person: Person) => {
  return `${person.firstName} ${person.lastName}`;
}

export const addS = (count: number) => count === 1 ? '' : 's';
