import { ComponentProps, ReactNode } from "react"
import { Button } from "./Button"

export const FormFieldLabel = ({ children, label, name, error, required, onAddClick, addLabel }: {
  label?: string; 
  error?: ReactNode; 
  size?: Size, 
  name: string, 
  required?: boolean,
  onAddClick?: () => void,
  addLabel?: string,
} & ComponentProps<"div">) => {
  return (
    <div>
      <div className="flex justify-between items-end">
        {label && <label className={error ? "text-danger" : ""} htmlFor={name}>{label}</label>}
        <div className="u-spread">
          {required && !error && <div className="text-sm text-gray-500">Required</div>}
          {onAddClick && addLabel && <Button type="button" skin="text" onClick={onAddClick}>{addLabel}</Button>}
          {error && <div className="text-sm ml-2">{error}</div>}
        </div>
      </div>

      {children}
    </div>
  )
}
