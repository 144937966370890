import { ComponentProps } from "react";
import cx from "classnames";
import ReactTooltip from "react-tooltip";
import { Spinner } from "./Spinner";

export const Button = ({
  children,
  skin,
  className,
  loading,
  loadingText,
  disabled,
  disabledTooltip,
  size = "md",
  ...props
}: {
  skin?: Skin;
  loading?: boolean;
  loadingText?: string;
  size?: Size;
  disabledTooltip?: string;
} & ComponentProps<"button">) => {
  const isTextOnly = skin && ["text", "dismiss"].includes(skin);

  const sizeStyle = {
    "px-3 py-1 rounded-[3px]": !isTextOnly && size === "sm",
    "px-4 py-2 rounded-[3px] min-w-[80px]": !isTextOnly && size === "md",
    "px-4 py-2 rounded-[5px]": !isTextOnly && size === "lg",
  };

  const fontSizeStyle = {
    "text-sm": size === "sm",
    "text-lg": size === "lg",
  };

  const colorStyle = disabled
    ? "bg-gray-400 text-gray-500"
    : {
      "border border-gray-600 bg-white text-gray-600 hover:border-primary hover:text-primary": !skin,
      "bg-primary hover:bg-primaryHover text-white": skin === "primary",
      "bg-darkBlueLight hover:bg-darkBlueHighlight text-white": skin === "secondary",
      "text-primary hover:text-primaryHover": skin === "text",
    };

  const _className = cx(
    "shrink-0 flex items-center transition-all duration-100 flex justify-center",
    className,
    sizeStyle,
    fontSizeStyle,
    colorStyle
  );

  return (
    <div className="inline-block" data-tip={disabled ? disabledTooltip : undefined}>
      <button
        disabled={loading || disabled}
        className={_className}
        {...props}
      >
        {loading && <Spinner className="mr-1" />}
        {loading && loadingText ? loadingText : children}
      </button>
      <ReactTooltip place="top" type="dark" effect="solid"/>
    </div>
  );
};
