//@ts-nocheck

import { useEffect, useRef } from "react"

export const ClickOutsideDetector = ({ onClickOutside, children, ...props }: { onClickOutside: () => void } & ComponentProps<"input">) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (!ref.current.contains(e.target)) {
        onClickOutside()
      }
    }
  
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref, onClickOutside]);
    
  return <div ref={ref} {...props}>{children}</div>
}
