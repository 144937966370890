import { useState } from "react";
import ReactDOM from "react-dom";
import { Button } from "../ui/Button";
import { Modal } from "../ui/Modal";

type ConfirmModalOptions = {
  onConfirm?: () => Promise<any>;
  title?: string;
  confirmBtnText?: string;
  cancelBtnText?: string
};

type Props = ConfirmModalOptions & {
  resolve: (value: boolean) => void;
  confirmText: string;
};

const ConfirmModal = ({ resolve, confirmText, onConfirm, title, confirmBtnText = "Yes", cancelBtnText = "No" }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleDeny = () => {
    resolve(false);
    removeModal();
  };

  const handleConfirm = async () => {
    if (onConfirm) {
      setLoading(true);
      await onConfirm();
    }

    resolve(true);
    removeModal();
  };

  return (
    <Modal onClose={handleDeny} title={title || "Are you sure?"}>
      <div>{confirmText}</div>
      <div className="flex justify-end mt-8">
        {!loading && (
          <Button className="mr-4" skin="dismiss" onClick={handleDeny}>
            {cancelBtnText}
          </Button>
        )}
        <Button skin="primary" loading={loading} onClick={handleConfirm}>
          {confirmBtnText}
        </Button>
      </div>
    </Modal>
  );
};

const removeModal = () => {
  const confirmModal = document.getElementById("confirmPortal");
  confirmModal && ReactDOM.unmountComponentAtNode(confirmModal);
};

export const confirmAction = (
  confirmText: string,
  opts?: ConfirmModalOptions
) => {
  return new Promise((resolve) => {
    const confirmModal = document.getElementById("confirmPortal");
    confirmModal &&
      ReactDOM.render(
        <ConfirmModal resolve={resolve} confirmText={confirmText} {...opts} />,
        confirmModal
      );
  });
};
