import { atom, useRecoilState } from "recoil";
import { api } from "./api";
import {replaceObjectById} from "./folders-api";

export const arcRequestsState = atom<ArcRequest[] | null>({
  key: "arcRequestsState",
  default: null,
});

export const useArcRequestsApi = () => {
  const [arcRequests, setArcRequests] = useRecoilState(arcRequestsState);

  return {
    create: async (data: any) => {
      try {
        const resp = await api.arcRequests.create(data);
        setArcRequests(arcRequests ? arcRequests.concat([resp.data]) : [resp.data]);
      } catch (e: any) {
        return console.error(e.message);
      }
    },
    update: async (id: number, data: ArcRequestUpdateReq) => {
      try {
        const resp = await api.arcRequests.update(id, data);
        setArcRequests((arcRequests) => replaceObjectById(arcRequests, resp.data));
      } catch (e: any) {
        return console.error(e.message);
      }
    },
    getList: async (hoaId: number) => {
      try {
        const response = await api.arcRequests.getList(hoaId);
        setArcRequests(response.data);
      } catch (e: any) {
        return console.error(e.message);
      }
    },
  };
};
