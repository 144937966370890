import { ChangeEvent, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { peopleState, usePeopleApi } from "../api/people-api";
import { AddOccupantModal } from "../components/AddOccupantModal";
import { DirectoryWizardModal } from "../components/DirectoryWizardModal";
import { EmptyState } from "../components/EmptyState";
import { PersonTypeDictionary } from "../consts";
import { Page } from "../layouts/Page";
import { Button } from "../ui/Button";
import { Table } from "../ui/Table";
import { TextField } from "../ui/TextField";
import { formatPhone } from "../utils/phone-utils";
import { _u } from "../utils/utils";

export const Directory = () => {
  const peopleApi = usePeopleApi();
  const people = useRecoilValue(peopleState);

  const [addOccupantModalVisible, setAddOccupantModalVisible] = useState(false);
  const [directoryWizardModalVisible, setDirectoryWizardModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    peopleApi.getList(1);
  }, [])

  const handleToggleAddOccupantModal = () => {
    setAddOccupantModalVisible(addOccupantModalVisible => !addOccupantModalVisible)
  }

  const handleToggleDirectoryWizardModal = () => {
    setDirectoryWizardModalVisible(directoryWizardModalVisible => !directoryWizardModalVisible)
  }

  if (!people || people.length === 0) {
    return (
      <Page title="Directory"> 
        <EmptyState
          illustration="directory"
          title="Build your directory"
          subtitle="Keep an up to date list of owners, tenants, and their contact info"
          actions={
            <Button size="lg" skin="primary" onClick={handleToggleDirectoryWizardModal}>
              Add Buildings, Units, Owners, and Tenants
            </Button>
          }
        />
        {directoryWizardModalVisible && (
          <DirectoryWizardModal onClose={handleToggleDirectoryWizardModal} />
        )}
      </Page>
    );
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  let filteredPeople = people;
  if (searchTerm) {
    filteredPeople = people.filter(({ unit, firstName, lastName, email, phone }) => (
      _u.includesFilter(unit.building.name + unit.identifier + firstName + lastName + email + phone, searchTerm)
    ))
  }

  const rows = filteredPeople.map(person => (
    [
      person.unit.building.name,
      person.unit.identifier, 
      `${person.firstName} ${person.lastName}`, 
      formatPhone(person.phone) || '--',
      person.email || '--',
      PersonTypeDictionary[person.type],
    ]
  ));

  return (
    <Page
      title="Directory" 
      actions={
        <div className="flex">
          <TextField icon="search" className="mr-3 w-64" onChange={handleSearch} name="search" placeholder="Search" />
          <Button onClick={handleToggleAddOccupantModal}>Add Owner/Tenant</Button>
        </div>
      }
    > 
      <Table
        headers={['Building', 'Unit', 'Name', 'Phone', 'Email', 'Type']}
        rows={rows}
      />
      {addOccupantModalVisible && (
        <AddOccupantModal onClose={handleToggleAddOccupantModal} />
      )}
    </Page>
  );
};
