import ArcsBase from "../img/hero/arcs-base.png"
import ArcsBase2x from "../img/hero/arcs-base@2x.png"
import Arcs from "../img/hero/arcs.png"
import Arcs2x from "../img/hero/arcs@2x.png"
import Base from "../img/hero/base.png"
import Base2x from "../img/hero/base@2x.png"
import Calendar from "../img/hero/calendar.png"
import Calendar2x from "../img/hero/calendar@2x.png"
import ClosingAlert from "../img/hero/closing-alert.png"
import ClosingAlert2x from "../img/hero/closing-alert@2x.png"
import Cursor from "../img/hero/cursor.png"
import Cursor2x from "../img/hero/cursor@2x.png"
import Feedback from "../img/hero/feedback.png"
import Feedback2x from "../img/hero/feedback@2x.png"
import Financials from "../img/hero/financials.png"
import Financials2x from "../img/hero/financials@2x.png"
import GoogleMaps from "../img/hero/google-maps.png"
import GoogleMaps2x from "../img/hero/google-maps@2x.png"
import Notifications from "../img/hero/notifications.png"
import Notifications2x from "../img/hero/notifications@2x.png"
import Quickbooks from "../img/hero/quickbooks.png"
import Quickbooks2x from "../img/hero/quickbooks@2x.png"
import Stripe from "../img/hero/stripe.png"
import Stripe2x from "../img/hero/stripe@2x.png"
import SwitchOn from "../img/hero/switch-on.png"
import SwitchOn2x from "../img/hero/switch-on@2x.png"
import ThreadBase from "../img/hero/thread-base.png"
import ThreadBase2x from "../img/hero/thread-base@2x.png"
import Thread from "../img/hero/thread.png"
import Thread2x from "../img/hero/thread@2x.png"
import ThreadsSelected2x from "../img/hero/threads-selected@2x.png"
import ThreadsSelected from "../img/hero/threads.png"
import Threads from "../img/hero/threads.png"
import Threads2x from "../img/hero/threads@2x.png"
import Zillow from "../img/hero/zillow.png"
import Zillow2x from "../img/hero/zillow@2x.png"

export const UiAnimation = () => {
  const s = (width: string | number, top: string | number, left: string | number, extra?: any) => ({
    position: 'absolute' as 'absolute',
    width,
    top,
    left,
    ...extra
  });

  return (
    <div className="w-full flex justify-center" style={{ padding: '2% 8% 8% 8%' }}>
      <div className="relative z-10 relative inline-block" style={{ width: '1050px' }}>
        <img id="png-base" src={Base} srcSet={`${Base2x} 2x`} className="w-full" alt="fireSpot"/>

        <div id="png-arcs-base">
          <img src={ArcsBase} srcSet={`${ArcsBase2x} 2x`} style={s('90%', '8%', '5%')} alt="fireSpot"/>
          <div style={s('90%', '35%', '4.5%', { height: '55%', overflow: 'hidden' })}>
            <img id="png-arcs" src={Arcs} srcSet={`${Arcs2x} 2x`} style={{ position: 'absolute', width: '100%', top: 0, left: 0 }} alt="fireSpot"/>
          </div>
        </div>

        <img id="png-zillow" src={Zillow} srcSet={`${Zillow2x} 2x`}  style={{ position: 'absolute', width: '13%', top: '-6%', left: '82%' }} alt="fireSpot"/>
        <div id="png-notifications" style={{ position: 'absolute', width: '29%', top: '29%', left: '82%', height: '35%' }}>
          <img src={Notifications} srcSet={`${Notifications2x} 2x`} style={s('100%', 0, 0)} alt="fireSpot"/>
          <img id="png-switch-on" src={SwitchOn} srcSet={`${SwitchOn2x} 2x`}  style={s('10%', '43%', '78.5%')} alt="fireSpot"/>
        </div>
        <img id="png-feedback" src={Feedback} srcSet={`${Feedback2x} 2x`} style={{ position: 'absolute', width: '19%', top: '81%', left: '11%' }} alt="fireSpot"/>
        <img id="png-quickbooks" src={Quickbooks} srcSet={`${Quickbooks2x} 2x`} style={{ position: 'absolute', width: '12%', top: '42%', left: '-5%' }} alt="fireSpot"/>
        <img id="png-financials" src={Financials} srcSet={`${Financials2x} 2x`} style={{ position: 'absolute', width: '42%', top: '-6%', left: '-8%' }} alt="fireSpot"/>

        <div id="png-thread-base">
          <img src={ThreadBase} srcSet={`${ThreadBase2x} 2x`} style={s('55%', '10%', '41%')} alt="fireSpot"/>
          <img id="png-threads" src={Threads} srcSet={`${Threads2x} 2x`} style={{ position: 'absolute', width: '34%', top: '10%', left: '5%' }} alt="fireSpot"/>
          <img id="png-threads-selected" src={ThreadsSelected} srcSet={`${ThreadsSelected2x} 2x`} style={{ position: 'absolute', width: '34%', top: '10%', left: '5%' }} alt="fireSpot"/>
          <div style={s('52.75%', '18%', '41%', { height: '72%', overflow: 'hidden' })}>
            <img id="png-thread" src={Thread} srcSet={`${Thread2x} 2x`} style={s('100%', '0', '0')} alt="fireSpot"/>
          </div>
        </div>
        <img id="png-calendar" src={Calendar} srcSet={`${Calendar2x} 2x`}  style={{ position: 'absolute', width: '35%', top: '-6%', left: '76%' }} alt="fireSpot"/>
        <img id="png-stripe" src={Stripe} srcSet={`${Stripe2x} 2x`}  style={{ position: 'absolute', width: '12.5%', top: '35%', left: '-8%' }} alt="fireSpot"/>
        <img id="png-google-maps" src={GoogleMaps} srcSet={`${GoogleMaps2x} 2x`} style={{ position: 'absolute', width: '14.5%', top: '-6%', left: '18%' }} alt="fireSpot"/>
        <img id="png-closing-alert" src={ClosingAlert} srcSet={`${ClosingAlert2x} 2x`} style={{ position: 'absolute', width: '35%', top: '75%', left: '16%' }} alt="fireSpot"/>

        <img id="png-cursor" src={Cursor} srcSet={`${Cursor2x} 2x`} style={s('3.5%', '82%', '41%')} alt="cursor"/>
      </div>
    </div>
  );
}
