import { useState } from "react";
import { Formik, Form, FormikValues } from "formik";
import * as Yup from "yup";
import { api } from "../api/api";
import { consts } from "../public-consts";
import { Button } from "../ui/Button";
import { FormField } from "../ui/FormField";
import { Modal } from "../ui/Modal";
import { FormikTextArea } from "../ui/TextArea";
import { FormikTextField } from "../ui/TextField";
import { _u } from "../utils/utils";

export const ContactUsModal = ({ onClose }: { onClose: () => void }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSent, setIsSent] = useState<boolean>(false);

  const handleSubmit = async ({ name, email, message }: FormikValues) => {
    setIsLoading(true);
    api.slack.contactUs({
      text: [`Name: ${name}`, `Email: ${email}`, `Message: ${message}`].join(
        "\n"
      ),
    });
    await _u.sleep(500);
    setIsLoading(false);
    setIsSent(true);
  };

  if (isSent) {
    return (
      <Modal onClose={onClose}>
        <div className="flex flex-col">
          <img
            className="py-6 place-self-center"
            src={consts.publicLogoPath}
            alt={consts.companyName}
          />
          <div className="text-2xl font-bold text-center">
            Thanks for getting in touch!
          </div>
          <div className="mb-8 text-sm">
            We love hearing from our customers. We'll review your message and
            get back to you as soon as we can.
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal onClose={onClose}>
      <div className="flex flex-col">
        <img
          className="py-6 place-self-center"
          src={consts.publicLogoPath}
          alt={consts.companyName}
        />
        <div className="text-2xl font-bold text-center">
          We love hearing from you
        </div>
        <div className="mb-8 text-sm">
          Have a question, comment, critique, or suggestion? Let us know!
        </div>
      </div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          message: "",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Required"),
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          message: Yup.string().required("Required"),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormField>
            <FormikTextField
              label="Name"
              name="name"
              type="text"
              placeholder="Name"
              autoFocus
            />
          </FormField>
          <FormField>
            <FormikTextField
              label="Email"
              name="email"
              type="text"
              placeholder="Email"
            />
          </FormField>
          <FormField>
            <FormikTextArea
              rows={4}
              label="Message"
              name="message"
              placeholder="Message"
            />
          </FormField>
          <div className="flex justify-end">
            <Button loading={isLoading} type="submit">
              Send
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};
