import { atom, useRecoilState } from "recoil";
import { api } from "../api/api";

export const foldersState = atom<Folder[] | null>({
  key: "foldersState",
  default: null,
});

export const useFoldersApi = () => {
  const [folders, setFolders] = useRecoilState(foldersState);

  return {
    create: async (data: FolderCreateReq) => {
      try {
        const resp = await api.folders.create(data);
        setFolders(folders ? folders.concat([resp.data]) : [resp.data]);
      } catch (e: any) {
        return console.error(e.message);
      }
    },
    update: async (id: number, data: FolderUpdateReq) => {
      try {
        const resp = await api.folders.update(id, data);
        setFolders((folders) => replaceObjectById(folders, resp.data));
      } catch (e: any) {
        return console.error(e.message);
      }
    },
    fetch: async (hoaId: number) => {
      try {
        const response = await api.folders.fetch(hoaId);
        setFolders(response.data);
      } catch (e: any) {
        return console.error(e.message);
      }
    },
    delete: async (id: number) => {
      try {
        await api.folders.delete(id);
        setFolders((folders) => removeObjFromArr(folders, "id", id));
      } catch (e: any) {
        return console.error(e.message);
      }
    },
  };
};

export const replaceObjectById = <T extends { id: number }>(
  arr: T[] | null,
  obj: T
) => {
  if (arr === null) {
    return arr;
  }

  return arr.filter((a) => a.id !== obj.id).concat([obj]);
};

const removeObjFromArr = <T>(arr: T[] | null, key: keyof T, val: any) => {
  if (arr === null) {
    return null;
  }

  return arr.filter((item) => item[key] !== val);
};
